/* eslint-disable react-hooks/exhaustive-deps */
import InputText from '../../components/InputText'
import ModalComponent from '../../components/ModalComponent'
import { useEffect, useState } from 'react'
import { validateYupSchema } from '../../helpers/utils'
import { _pushToastMessage } from '../../helpers/messages'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import RoomService from '../../services/rooms.service'
import ErrorValidation from '../../components/ErrorValidation'
import { SelectPicker, TagPicker } from 'rsuite'
import PlaceService from '../../services/places.service'
import { CreateRoomSchema } from '../../validations/room.validations'
import CategoryService from '../../services/category.service'
import SelectWithCategoryImage from '../../components/SelectWithCategoryImage'

interface IRoomForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    getData: () => void
}
const RoomForm = ({ handleClose, action, open, data, getData }: IRoomForm) => {
    const dispatch = useDispatch()
    const { loader } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: {
            name: '',
            description: '',
            place_id: '',
            logo_img: '',
            patients_id: [],
            media_id: null,
        },
        validations: {},
    })

    const [places, setPlaces] = useState<any[]>([])
    const [medias, setMedias] = useState<any[]>([])
    const [patientsByPlace, setPatientsByPlace] = useState<any[]>([])

    useEffect(() => {
        if (!open) return

        _handleState()
    }, [open])

    const _handleState = async () => {
        const places = await _getPlaces()
        setPlaces([...places])
        _getPlaceCategoryData()
        if (action === 'update') {
            const place = places.find((p: any) => p.id === data.place_id)
            setPatientsByPlace([...place?.patients])
            setState({
                ...state,
                validations: {},
                data: {
                    ...data,
                    patients_id: data.patients.map((p: any) => p.id),
                },
            })
            return
        }

        setState({
            ...state,
            validations: {},
            data: {
                name: '',
                description: '',
                place_id: '',
                logo_img: '',
                patients_id: [],
                media_id: null,
            },
        })
    }

    const _getPlaces = async () => {
        try {
            const { data } = await new PlaceService().getSelectPlaces()
            return data
        } catch (err: any) {
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    const _getPlaceCategoryData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { data } = await new CategoryService().getCategory({ params: { search: 'places' } })
            setMedias([...data?.medias, { id: 'other', name: 'Otro' }])
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            console.log(e)
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleRSuiteOnChange = (value: any, name: string) => {
        if (name === 'place_id') {
            const place = places.find((p: any) => p.id === value)
            const { patients } = place
            setPatientsByPlace([...patients])
            state.data.patients_id = []
        }

        if (name === 'media_id' && value !== 'other') {
            state.data.logo_img = ''
        }

        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const schema = CreateRoomSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            if (action === 'update') {
                await new RoomService().updateRoom(state.data)
            }

            if (action === 'create') {
                await new RoomService().createRoom(state.data)
            }

            dispatch(setLoading({ isLoading: false }))

            handleClose()
            getData()

            const message = action === 'create' ? 'Estancia creada con exito' : 'Estancia actualizada con exito'
            _pushToastMessage({ type: 'success', header: 'Exito', text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    return (
        <ModalComponent
            open={open}
            title={`${action === 'create' ? 'Crear Estancia' : 'Actualizar Estancia'}`}
            handleClose={handleClose}
        >
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Hogar</span>
                    <SelectPicker
                        data={places}
                        onChange={(data) => _handleRSuiteOnChange(data, 'place_id')}
                        className="w-full"
                        placeholder="Informa el hogar"
                        labelKey="name"
                        valueKey="id"
                        value={state.data?.place_id}
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="place_id"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>
                <div className="mb-2 w-full">
                    <InputText
                        name="name"
                        type="text"
                        label="Nombre"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.name}
                    />
                </div>
                <div className="mb-2 w-full">
                    <InputText
                        name="description"
                        type="text"
                        label="Descripción"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.description}
                    />
                </div>

                <div className="mb-2 w-full">
                    <label className="text-[12px]">Usuarios</label>
                    <TagPicker
                        data={patientsByPlace}
                        labelKey="name"
                        valueKey="id"
                        placeholder="Informa los usuario"
                        locale={{ noResultsText: 'No se encontraron resultados', searchPlaceholder: 'Buscar usuario' }}
                        style={{ width: '100%' }}
                        value={state.data?.patients_id}
                        onChange={(value: any) => _handleRSuiteOnChange(value, 'patients_id')}
                        disabled={!state.data?.place_id}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name={'patients_id'}
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                <SelectWithCategoryImage
                    onChange={(value: any, name: string) => _handleRSuiteOnChange(value, name)}
                    value={state.data?.media_id}
                    data={medias}
                />

                {state.data?.logo_img && (
                    <div className="mb-2 mt-4 ps-5 w-full">
                        <img src={state.data?.logo_img} alt="logo" className="w-[130px]" />
                    </div>
                )}

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default RoomForm
