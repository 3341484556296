import { days_of_week as daysOfWeek } from "../../helpers/days_of_week";
interface IRoutineDetails {
    id: string;
    name: string;
    description: string;
    user: any;
    patient: any;
    qr_code: string;
    command?: any
    start_time: string;
    days_of_week: string;
    order: string | number;
    deleted_at: string;
    onClose: () => void;
}
const RoutineDetails = ({ id, name, description, user, patient, qr_code, days_of_week, onClose, start_time }: IRoutineDetails) => {

    const DaysOfWeek = ({ data }: any) => {
        const days = data?.split(',').map((d: string) => parseInt(d));
        days.sort((a: number, b: number) => a - b);
        return (
            <>
                {days?.map((day: string, index: number) => (
                    <span key={index} className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 m-1">
                        {daysOfWeek.find((d: any) => d.value === parseInt(day))?.label}
                    </span>
                ))}
            </>
        );
    }

    return (
        <div className="w-full flex flex-wrap">
            <div className="w-[50%] mb-4">
                <label className="text-gray-500 text-[12px]">Nombre</label>
                <div className="ps-1 w-full text-[12px]">{name}</div>
            </div>

            <div className="w-[50%] mb-4">
                <label className="text-gray-500 text-[12px]">QR Code</label>
                <div className="ps-1 w-full text-[12px]">
                    <a href={`routine/${id}`} target="_blank" rel="noreferrer">
                        Imprimir codigo QR
                    </a>
                </div>
            </div>

            <div className="w-[50%] mb-4">
                <label className="text-gray-500 text-[12px]">Usuario</label>
                <div className="ps-1 w-full text-[12px]">{patient?.name}</div>
            </div>

            <div className="w-[50%] mb-4">
                <label className="text-gray-500 text-[12px]">Hora inicio</label>
                <div className="ps-1 w-full text-[12px]">{start_time}</div>
            </div>

            <div className="w-full mb-4">
                <label className="text-gray-500 text-[12px]">Dias de la semana</label>
                <div className="w-full flex flex-wrap">
                    <DaysOfWeek data={days_of_week} />
                </div>
            </div>

            <div className="w-full mb-4">
                <label className="text-gray-500 text-[12px]">Descripcion</label>
                <div className="ps-1 w-full text-[12px]">{description}</div>
            </div>

            <div className="w-full mt-7 flex justify-between items-end">
                <div className="text-[12px] text-gray-400">
                    {user && `Creado por: ${user?.name}`}
                </div>
                <button className=" button me-3 border-red-400" onClick={onClose}>Cerrar</button>
            </div>
        </div>
    );
}

export default RoutineDetails;