export function toBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const convertToBase64AndFormatImage = async (
  payload: any,
  fileList: any,
  type: any = null
) => {
  const fileImage = payload[fileList.length > 0 ? fileList.length : 0];
  const image = await toBase64(fileImage.blobFile);

  const name_original = fileImage.blobFile.name.replace(/\s/g, "-");

  const imageData = {
    info: { Nota: "agrega informacion relevante" },
    name_original: name_original,
    type: type ? type : "product",
    element_pk: "",
    alt: "Imagen",
    base64: image,
  };
  return imageData;
};
