import axios from 'axios'
import store from '../redux/store'
import { signout } from '../redux/slicers/session'
import { _pushToastMessage } from '../helpers/messages'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
})

instance.interceptors.request.use(
    function (config) {
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    function (response: any) {
        return response
    },
    function (error: any) {
        if (error.response && error.response.status === 401) {
            const dispatch: any = store.dispatch

            _pushToastMessage({
                type: 'warning',
                header: 'Sesión expirada',
                text: 'Su sesion ha expirado, por favor inicie sesión nuevamente',
            })

            dispatch(
                signout({
                    isLogged: false,
                    profile: null,
                    tokens: null,
                })
            )
        }
        return Promise.reject(error)
    }
)

export default instance
