const TermsAndConditions = () => {
    return (
        <div className="min-h-[100vh] flex justify-center bg-gray-200 text-gray-700 p-5">
            <div className="rounded-lg shadow-md bg-white px-8 pt-10 pb-4 md:min-w-[90%] max-w-[99%]">
                <h1 className="mb-8 text-center">Términos de uso</h1>
                <p>
                    Al utilizar nuestra aplicación, usted acepta estos Términos de Uso. Si no está de acuerdo con estos
                    términos, no debe usar nuestra aplicación.
                </p>

                <p className="my-5"><b>Uso de la Aplicación:</b></p>

                <p>Debe tener al menos 13 años para usar Lectogram.</p>
                <p>Usted es responsable de mantener la confidencialidad de su cuenta y contraseña.</p>

                <p className="my-5"><b>Contenido del Usuario:</b></p>

                <p>Usted conserva los derechos sobre el contenido que crea y comparte en Lectogram.</p>

                <p>No debe utilizar Lectogram para distribuir contenido ilegal, ofensivo o que infrinja derechos de terceros.</p>

                <p className="my-5"><b>Propiedad Intelectual:</b></p>

                <p>Lectogram y su contenido original son propiedad de Universidad de Las Américas (UDLA) y están protegidos por las leyes de derechos de autor.</p>

                <p className="my-5"><b>Limitación de Responsabilidad:</b></p>

                <p>Lectogram no será responsable por cualquier daño directo, indirecto, incidental o consecuente que resulte del uso o la imposibilidad de usar nuestra aplicación.</p>

                <p className="my-5"><b>Modificaciones del Servicio:</b></p>

                <p>Nos reservamos el derecho de modificar o descontinuar el servicio en cualquier momento sin previo aviso.</p>

                <p className="my-5"><b>Contacto:</b></p>

                <p>Para cualquier consulta sobre estos términos, puede contactarnos en <a href="mailto:lectogram@udla.cl">lectogram@udla.cl</a></p>



            </div>
        </div>
    )
}

export default TermsAndConditions
