import { Table } from 'rsuite'
import WhisperComponent from '../components/WhisperComponent'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import moment from 'moment'
import LogModal from '../modals/Logs/LogModal'

interface ILogsTable {
    data: any
}
const LogsTable = ({ data }: ILogsTable) => {
    const { loader } = useSelector((state: any) => state)

    const [modals, setModals] = useState<any>({
        logsModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
    })

    return (
        <div className="w-full">
            <LogModal
                open={modals.logsModal.open}
                title="Log"
                handleClose={() => setModals({ ...modals, logsModal: { ...modals.logsModal, open: false } })}
                data={modals.logsModal.data}
            />

            <Table
                autoHeight
                rowHeight={60}
                className="w-[100%]"
                data={data}
                locale={{ emptyMessage: 'No hay nada por aquí' }}
                loading={loader.isLoading}
            >
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">ID</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return (
                                <WhisperComponent text={`${item.id}`}>
                                    <button
                                        className="text-blue-400 underline"
                                        onClick={() =>
                                            setModals({
                                                ...modals,
                                                logsModal: { ...modals.logsModal, open: true, data: item },
                                            })
                                        }
                                    >
                                        <span className="size-08">{`${item.id}`}</span>
                                    </button>
                                </WhisperComponent>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Usuario</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return (
                                <WhisperComponent text={`${item.user?.name || "No informado"}`}>
                                    <span className="size-08">{`${item.user?.name || "No informado"}`}</span>
                                </WhisperComponent>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Acción</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return (
                                <WhisperComponent text={`${item.action}`} capitalizeOff>
                                    <span className="size-08">{`${item.action}`}</span>
                                </WhisperComponent>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Descripción</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return (
                                <WhisperComponent capitalizeOff text={`${item.description}`}>
                                    <span className="size-08">{`${item.description}`}</span>
                                </WhisperComponent>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Creado al dia</Table.HeaderCell>
                    <Table.Cell>
                        {(item) => {
                            return (
                                <WhisperComponent text={`${moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}`}>
                                    <span className="size-08">{`${moment(item.created_at).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}`}</span>
                                </WhisperComponent>
                            )
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </div>
    )
}

export default LogsTable
