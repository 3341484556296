import instance from "./api";
import store from "../redux/store";

class RestService {
  _setTokens() {
    const { session } = store.getState();
    const { tokens } = session;
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokens.accessToken}`;
  }

  async get({ url }: { url: string }) {
    this._setTokens();
    try {
      const response = await instance.get(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default RestService;
