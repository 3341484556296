import { isAdmin, isTutor, loggedUserHasPermitionTo } from "../../helpers/reduxHelpers";

export const _actionMenuTaskTableSpeaker = (data: any, onSelect: any) => {
  return {
    menu: [
      {
        label: `${data?.deleted_at ? "Restaurar" : "Desactivar"} tarea`,
        Icon: `zmdi zmdi-${data.deleted_at ? "refresh-alt" : "delete"} me-2`,
        show: loggedUserHasPermitionTo(["delete"]) && (isAdmin() || isTutor()),
        eventKey: 1,
      },
      {
        label: `Editar tarea`,
        Icon: `zmdi zmdi-edit me-2`,
        show: loggedUserHasPermitionTo(["update"]) && (isAdmin() || isTutor()),
        eventKey: 2,
      },
      {
        label: `Escuchar descripción`,
        Icon: `zmdi zmdi-play-circle me-2`,
        show: true,
        eventKey: 3,
      },
      {
        label: `Configuraciones de voz`,
        Icon: `zmdi zmdi-brightness-5 me-2`,
        show: true,
        eventKey: 4,
      },
    ],
    _handleSelect: (eventKey: any, payload: any) =>
      onSelect({
        option: eventKey,
        payload: payload,
      }),
    data: data,
  };
};

export const _actionMenuPreLoadedTaskTableSpeaker = (data: any, onSelect: any) => {
  return {
    menu: [
      {
        label: `${data?.deleted_at ? "Restaurar" : "Desactivar"} tarea`,
        Icon: `zmdi zmdi-${data.deleted_at ? "refresh-alt" : "delete"} me-2`,
        show: loggedUserHasPermitionTo(["delete"]) && (isAdmin() || isTutor()),
        eventKey: 1,
      },
      {
        label: `Editar tarea`,
        Icon: `zmdi zmdi-edit me-2`,
        show: loggedUserHasPermitionTo(["update"]) && (isAdmin() || isTutor()),
        eventKey: 2,
      },
      {
        label: `Escuchar descripción`,
        Icon: `zmdi zmdi-play-circle me-2`,
        show: true,
        eventKey: 3,
      }
    ],
    _handleSelect: (eventKey: any, payload: any) =>
      onSelect({
        option: eventKey,
        payload: payload,
      }),
    data: data,
  };
};
