/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react"

const ErrorValidation = (props: any) => {
  const { validations, name, className, ...rest } = props
  const errorRef = useRef<any>(null)

  useEffect(() => {
    if (validations && validations[name] && validations[name].length) {
      errorRef?.current?.classList.add('error-enabled')
    } else {
      errorRef?.current?.classList.remove('error-enabled')
    }
  }, [validations])
  return (
    <div className={`error-validations ${className}`} ref={errorRef} {...rest}>
      {validations && validations[name] && validations[name].length > 0 && (
        <span>{validations[name][0]}</span>
      )}
    </div>
  )
}

export default ErrorValidation