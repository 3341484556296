/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserService from '../../services/users.service'
import { _pushToastMessage } from '../../helpers/messages'
import { setLoading } from '../../redux/slicers/loader'
import InputText from '../../components/InputText'
import { validateYupSchema } from '../../helpers/utils'
import { ValidateEmail } from '../../validations/user.validations'
const { useNavigate } = require('react-router-dom')

const Recovery = () => {
    const [state, setState] = useState({
        validations: {},
        data: {
            email: '',
        },
    })

    const dispatch = useDispatch()
    const { session, loader } = useSelector((state: any) => state)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setLoading({ isLoading: false }))
        if (session.isLogged) {
            if (session?.profile?.roles[0]?.value === 'paciente') {
                navigate('/routines')
                return
            }

            navigate('/users')
        }
    }, [session])

    const _handleOnChange = (e: any) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value,
            }
        })
    }

    const _handleRecovery = async () => {
        const validations = await validateYupSchema(ValidateEmail, state.data)
        if (validations) {
            setState({
                ...state,
                validations,
            })
            return
        }

        setState({
            ...state,
            validations: {},
        })

        try {
            dispatch(setLoading({ isLoading: true }))
            const User = new UserService()
            await User.recoveryPassword(state.data)
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({
                type: 'success',
                header: 'Exito',
                text: 'Se ha enviado un correo con las instrucciones para recuperar su contraseña',
            })
        } catch (e: any) {
            dispatch(setLoading({ isLoading: false }))
            const errorMessage = e.response?.data?.message ?? e.message ?? 'No fue posible iniciar sesion'
            _pushToastMessage({ type: 'error', header: 'Error', text: errorMessage })
        }
    }

    return (
        <div className="min-h-[100vh] flex justify-center items-center bg-gray-200 text-gray-700">
            <div className="rounded-lg shadow-md bg-white px-8 pt-10 pb-4 md:min-w-[320px] max-w-[90%]">
                <div className="text-center text-[30px] font-bold">Lectogram</div>

                <div className="text-center font-bold mt-3 text-[24px]">Recuperación de contraseña</div>

                <div className="mt-5">
                    <InputText
                        name="email"
                        label=""
                        className='shadow-none outline-none w-full border-b-[1px] my-5 text-[13px]'
                        placeholder='Informa su correo electronico'
                        value={state.data.email}
                        validations={state.validations}
                        onChange={_handleOnChange}
                    />
                </div>

                <div className="text-center mt-5">
                    <button
                        className="border-b-blue-300 border-b-2 px-2 rounded-sm hover:text-blue-300"
                        onClick={_handleRecovery}
                        disabled={loader.isLoading}
                    >
                        Enviar
                    </button>
                </div>

                <div className="text-center mt-20 text-[12px] ">
                    <a href="/signin" className="text-blue-300 hover:text-blue-400">
                        {' '}
                        Volver
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Recovery
