import instance from './api'
import store from '../redux/store'

interface IUser {
    id?: string
    name: string
    email: string
    phone_number: string
    role: string
    password: string
    consfirm_password?: string
    log?: any
}

interface IUserProfile {
    id: string
    name: string
    email: string
    phone_number: string
}

interface ISignInUser {
    email: string
    password: string
    log?: any
}
class UserService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async createUser(user: IUser) {
        try {
            const response = await instance.post('/api/register', user)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createInvitedUser(user: IUser) {
        try {
            const response = await instance.post('/api/register/invited', user)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createAdminUser(user: IUser) {
        this._setTokens()
        try {
            const response = await instance.post('/api/users/create', user)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updatePassword({ password, id }: { password: string; id: number }) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/password/update/${id}`, {
                password,
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateUser(id: number, user: IUser) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/update/${id}`, user)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateProfile(id: number, user: IUserProfile) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/profile/update/${id}`, user)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async signinUser(user: ISignInUser) {
        try {
            const response = await instance.post('/api/login', {
                ...user,
                log: { save: true, action: 'login', level: 'info' },
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async signOut() {
        this._setTokens()
        try {
            const response = await instance.get('/api/logout')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getProfile({ token }: { token?: string }) {
        this._setTokens()
        try {
            let response = null
            if (token) {
                response = await instance.get('/api/profile', {
                    headers: { Authorization: `Bearer ${token}` },
                })
            } else {
                response = await instance.get('/api/profile')
            }
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getUsers() {
        this._setTokens()
        try {
            const response = await instance.get('/api/users')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getPatients() {
        this._setTokens()
        try {
            const response = await instance.get('/api/users/patients')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async disableUser(id: string) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/users/toggle-status/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async searchUsers(query: string) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/users/search?search=${query}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async filterUsers(query: string) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/users/filter?${query}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async searchPatient(query: string) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/users/search/patient?search=${query}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
    async searchPatientsToSelect(query: string) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/users/search/patient-to-select?search=${query}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async searchCaregiver(query: string) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/users/search/caregiver?search=${query}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getTutorPatients() {
        this._setTokens()
        try {
            const response = await instance.get(`/api/users/tutor/patients`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getCaregiverPatients() {
        this._setTokens()
        try {
            const response = await instance.get(`/api/users/caregiver/patients`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async attachCaregiverToPatient({ caregiver_id, patient_id }: { caregiver_id: string; patient_id: string }) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/attach/caregiver/patient/${patient_id}`, {
                caregiver_id,
                log: { description: 'actualiza lista de usuarios', action: 'sync_patients_caregiver', level: 'info' },
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async detachCaregiverToPatient({ caregiver_id, patient_id }: { caregiver_id: string; patient_id: string }) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/detach/caregiver/patient/${patient_id}`, {
                caregiver_id,
                log: { description: 'actualiza lista de usuarios', action: 'sync_patients_caregiver', level: 'info' },
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async syncCaregiverPatients({ caregiver_id, patients }: { caregiver_id: string; patients: number[] }) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/sync/patients/caregiver/${caregiver_id}`, {
                patients,
                log: { description: 'actualiza lista de usuarios', action: 'sync_patients_caregiver' },
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async syncTutorPatients({ tutor_id, patients }: { tutor_id: string; patients: number[] }) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/sync/patients/tutor/${tutor_id}`, {
                patients,
                log: { description: 'actualiza lista de usuarios', action: 'sync_patients_tutor' },
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async syncPermitions({ user_id, permitions }: { user_id: string; permitions: number[] }) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/users/sync/permitions/${user_id}`, {
                permitions,
                log: { description: 'actualiza lista de usuarios', action: 'sync_patients_caregiver' },
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async inviteUser(payload: { email: string; role_id: string; name: string }) {
        this._setTokens()
        try {
            const response = await instance.post('/api/users/invite', payload)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getInvitation(token: string) {
        this._setTokens()
        try {
            const response = await instance.get('/api/users/invitation/' + token)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async recoveryPassword(payload: { email: string }) {
        this._setTokens()
        try {
            const response = await instance.post('/api/recovery/password', payload)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updatePasswordWithToken(payload: { password: string; token: string }) {
        this._setTokens()
        try {
            const response = await instance.post('/api/recovery/password/token', payload)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default UserService
