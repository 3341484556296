/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "rsuite"
import WhisperComponent from "../../../components/WhisperComponent"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setLoading } from "../../../redux/slicers/loader";

const Caregivers = ({ data, handleClose }: any) => {

    const {loader} = useSelector((state: any) => state);
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState<any>([]);

    useEffect(() => {
        dispatch(setLoading({ isLoading: true }))
        setTimeout(() => {
            setTableData(data)
            dispatch(setLoading({ isLoading: false }))
        }, 500)
    }, [])

    return <div className="w-full">
        <Table data={tableData || []} className="w-full" locale={{ emptyMessage: "No se encontraron cuidadores asociados a este usuario" }} autoHeight loading={loader.isLoading} >
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">ID</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.id}`} >
                                        <span className="size-08">{`${user.id}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Nombre</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.name}`} >
                                        <span className="size-08">{`${user.name}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Email</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`${user.email?.toLowerCase()}`} >
                                        <span className="size-08">{`${user.email?.toLowerCase()}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Teléfono</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.phone_number}`} >
                                        <span className="size-08">{`${user.phone_number}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>
            </Table>

            <div className="flex justify-end w-full mt-10">
                <button className=" button me-3 border-red-400" onClick={handleClose} >Cerrar</button>
            </div>
    </div>
}

export default Caregivers
