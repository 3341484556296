/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import InputText from '../../components/InputText'
import ModalComponent from '../../components/ModalComponent'
import { _pushToastMessage } from '../../helpers/messages'
import { SelectPicker } from 'rsuite'
import ErrorValidation from '../../components/ErrorValidation'
import { validateYupSchema } from '../../helpers/utils'
import { InvitationUserSchema } from '../../validations/user.validations'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import RolesService from '../../services/roles.service'
import { isAdmin, isCaregiver, isTutor } from '../../helpers/reduxHelpers'
import IRole from '../../interfaces/IRole'
import UserService from '../../services/users.service'

interface IInvitationForm {
    open: boolean
    handleClose: () => void
}

const InvitationForm = ({ open, handleClose }: IInvitationForm) => {
    const dispatch = useDispatch()
    const loader = useSelector((state: any) => state.loader)

    const [state, setState] = useState({
        data: {
            email: '',
            role_id: '',
            name: '',
        },
        validations: {},
    })

    const [roles, setRoles] = useState<any>([])

    useEffect(() => {
        _getRoles()
    }, [])

    const _getRoles = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const roles = await new RolesService().getRoles()

            if (isAdmin()) {
                setRoles(roles.data)
            }

            if (isTutor()) {
                const filteredRoles = roles.data.filter(
                    (role: IRole) => role.value === 'paciente' || role.value === 'cuidador'
                )
                setRoles(filteredRoles)
            }

            if (isCaregiver()) {
                const filteredRoles = roles.data.filter((role: IRole) => role.value === 'paciente')
                setRoles(filteredRoles)
            }

            dispatch(setLoading({ isLoading: false }))
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No fue posible cargar las roles' })
        }
    }

    const _handleSubmit = async (e: any) => {
        try {
            e.preventDefault()
            const validations = await validateYupSchema(InvitationUserSchema, state.data)
            setState({
                ...state,
                validations,
            })

            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            await new UserService().inviteUser(state.data)
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'success', header: 'Éxito', text: 'Usuario invitado con éxito' })
            handleClose()
        } catch (err: any) {
            dispatch(setLoading({ isLoading: true }))
            const error_message = err?.response?.data?.message || 'Ocurrió un error al invitar al usuario'
            _pushToastMessage({ type: 'error', header: 'Error', text: error_message })
        }
    }

    const _handleOnChange = (e: any) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value,
            },
        })
    }

    const _handleOnChangeRsuite = ({ name, value }: { name: string; value: string }) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    return (
        <ModalComponent open={open} title="Invita a un usuario" handleClose={handleClose}>
            <form onSubmit={_handleSubmit} className="w-full" autoComplete="off">
                <div className="w-full">
                    <div className="w-full mb-2">
                        <InputText
                            name="name"
                            label="Nombre"
                            validations={state.validations}
                            onChange={_handleOnChange}
                        />
                    </div>
                    <div className="w-full mb-2">
                        <InputText
                            name="email"
                            label="Email"
                            validations={state.validations}
                            onChange={_handleOnChange}
                        />
                    </div>
                    <div className="mb-2 w-full">
                        <span className={`text-[12px] `}>Role</span>
                        <SelectPicker
                            className="w-full "
                            value={state.data?.role_id}
                            data={roles}
                            onChange={(value: any) =>
                                _handleOnChangeRsuite({
                                    value,
                                    name: 'role_id',
                                })
                            }
                            valueKey="id"
                            placeholder="Seleccione un rol"
                            loading={loader.isLoading}
                            locale={{
                                emptyMessage: 'No hay resultados',
                                searchPlaceholder: 'Buscar',
                                noResultsText: 'No hay resultados',
                            }}
                        />
                        <ErrorValidation
                            validations={state.validations}
                            name="role_id"
                            className="text-red-400 text-[11px] text-end"
                        />
                    </div>

                    <div className="flex justify-end w-full mt-10">
                        <button type="button" className=" button me-3" onClick={handleClose}>
                            Cerrar
                        </button>
                        <button type="submit" className="button" disabled={loader.isLoading}>
                            Confirmar
                        </button>
                    </div>
                </div>
            </form>
        </ModalComponent>
    )
}

export default InvitationForm
