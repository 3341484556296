import { Table } from "rsuite";
import WhisperComponent from "../components/WhisperComponent";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../services/users.service";
import { setLoading } from "../redux/slicers/loader";
import { _pushToastMessage } from "../helpers/messages";

interface IUsersTableTutor {
    data: any
    tutor: any
    updateTutorData: (data: any) => void
}

const UsersTableTutor = ({ data, tutor, updateTutorData }: IUsersTableTutor) => {
    const { loader } = useSelector((state: any) => state);

    const dispatch = useDispatch();

    const _isThisPossibleToAssign = (user_id: any) => {
        if (tutor?.list_of_patients_to_tutor?.find((user: any) => user.id === user_id)) return false
        return true
    }

    const _handleAssignUser = async (user_id: number) => {
        try {
            dispatch(setLoading({ isLoading: true }))

            let patients = tutor?.list_of_patients_to_tutor?.map((user: any) => user.id)
            const isThisPossibleToAssign = _isThisPossibleToAssign(user_id);

            if (isThisPossibleToAssign) patients?.push(user_id)

            if (!isThisPossibleToAssign) patients = patients?.filter((id: any) => id !== user_id)

            const response = await new UserService().syncTutorPatients({ tutor_id: tutor.id, patients: patients })
            tutor.list_of_patients_to_tutor = response.data?.user?.list_of_patients_to_tutor
            updateTutorData({ ...tutor })
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: "success", header: "Exito", text: "Acción completada con exito" })
        } catch (error) {
            _pushToastMessage({ type: "error", header: "Error", text: "Ocurrió un error al asignar el usuario" })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <Table autoHeight rowHeight={60} className="w-[100%]" data={data} locale={{ emptyMessage: "No se encontraron usuarios con los datos informados" }} loading={loader.isLoading}>
            <Table.Column flexGrow={1}>
                <Table.HeaderCell align="center">ID</Table.HeaderCell>
                <Table.Cell >
                    {
                        (user) => {
                            return (
                                <WhisperComponent text={`${user.id}`} >
                                    <span className="size-08">{`${user.id}`}</span>
                                </WhisperComponent>
                            )
                        }
                    }
                </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell align="center">Nombre</Table.HeaderCell>
                <Table.Cell >
                    {
                        (user) => {
                            return (
                                <WhisperComponent text={`${user.name}`} >
                                    <span className="size-08">{`${user.name}`}</span>
                                </WhisperComponent>
                            )
                        }
                    }
                </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell align="center">Email</Table.HeaderCell>
                <Table.Cell >
                    {
                        (user) => {
                            return (
                                <WhisperComponent capitalizeOff text={`${user.email}`} >
                                    <span className="size-08">{`${user.email}`}</span>
                                </WhisperComponent>
                            )
                        }
                    }
                </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell align="center">Teléfono</Table.HeaderCell>
                <Table.Cell >
                    {
                        (user) => {
                            return (
                                <WhisperComponent text={`${user.phone_number}`} >
                                    <span className="size-08">{`${user.phone_number}`}</span>
                                </WhisperComponent>
                            )
                        }
                    }
                </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell align="center">Status</Table.HeaderCell>
                <Table.Cell >
                    {
                        (user) => {
                            return (
                                <WhisperComponent text={`${user.deleted_at ? "Inactivo" : "Activo"}`} >
                                    <span className="size-08">{`${user.deleted_at ? "Inactivo" : "Activo"}`}</span>
                                </WhisperComponent>
                            )
                        }
                    }
                </Table.Cell>
            </Table.Column>

            <Table.Column flexGrow={1}>
                <Table.HeaderCell align="center">Acciones</Table.HeaderCell>
                <Table.Cell >
                    {
                        (user) => {
                            return (
                                <div className="flex justify-center">
                                    <WhisperComponent text={_isThisPossibleToAssign(user.id) ? "Asignar" : "Eliminar"} >
                                        <button onClick={() => _handleAssignUser(user.id)}>
                                            <i className={`zmdi zmdi-${_isThisPossibleToAssign(user.id) ? "assignment-account" : "delete"} me-2`} />
                                        </button>
                                    </WhisperComponent>
                                </div>
                            )
                        }
                    }
                </Table.Cell>
            </Table.Column>

        </Table>
    )
}

export default UsersTableTutor;