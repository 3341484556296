/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import TaskHeader from './TasksHeader'
import TaskService from '../../services/task.service'
import { useParams } from 'react-router-dom'
import { IRoutines } from '../../interfaces/IRoutines'
import TasksTable from '../../tables/TasksTable'
import TaskForm from '../../forms/Tasks'
import BackButton from '../../components/BackButton'
import TaskConfigForm from '../../forms/Tasks/TaskConfigForm'

const Tasks = () => {
    const [data, setData] = useState<IRoutines>({
        days_of_week: '',
        description: '',
        name: '',
        order: 0,
        patient_id: 0,
        qr_code: '',
        start_time: '',
        tasks: [],
        user_id: 0,
    })

    const [modals, setModals] = useState<any>({
        taskFormModal: {
            open: false,
            action: 'create',
            title: '',
            data: null,
        },
        taskConfigFormModal: {
            open: false,
            action: 'create',
            title: '',
            data: null,
        },
    })

    const dispatch = useDispatch()

    const params = useParams()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new TaskService().getTasks({ id: params.id })
            response.data.tasks = response.data.tasks.map((item: any) => {
                item.voice = {
                    text: item.name,
                    lang: 'es',
                    isPlaying: false,
                }
                return item
            })
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'No se pudo cargar las tareas',
            })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    return (
        <div className="w-full">
            <div className="w-full">
                <BackButton />
            </div>

            <TaskForm
                open={modals.taskFormModal.open}
                handleClose={() => _handleToggleModal('taskFormModal')}
                action={modals.taskFormModal.action}
                setData={setData}
                data={modals.taskFormModal.data}
            />

            <TaskConfigForm
                open={modals.taskConfigFormModal.open}
                title="Configuración de voz"
                data={modals.taskConfigFormModal.data}
                handleClose={() => _handleToggleModal('taskConfigFormModal')}
                setData={setData}
            />

            <div className="w-full">
                <TaskHeader handleToggleModal={_handleToggleModal} />
            </div>

            <div className="w-full">
                <TasksTable
                    data={data}
                    setData={setData}
                    onEditTask={(data: any) => _handleToggleModal('taskFormModal', 'update', data)}
                    onEditTaskConfig={(data: any) => _handleToggleModal('taskConfigFormModal', 'update', data)}
                />
            </div>
        </div>
    )
}

export default Tasks
