import { isAdmin, isCaregiver, isTutor } from "../../helpers/reduxHelpers";

export const _taskActionMenuSpeaker = (data: any, onSelect: any) => {
  return {
    menu: [
      {
        label: "Crear tarea",
        Icon: "zmdi zmdi-plus-circle-o me-2",
        show: isAdmin() || isTutor() || isCaregiver(),
        eventKey: 1,
      },
      {
        label: "Filtros",
        Icon: "zmdi zmdi-filter-list me-2",
        show: false,
        eventKey: 2,
      },
    ],
    _handleSelect: (eventKey: any, payload: any) =>
      onSelect({
        option: eventKey,
        payload: payload,
      }),
    data: data,
  };
};
