import { useState } from 'react'
import { Uploader } from 'rsuite'
import { toBase64 } from '../../helpers/images'

const UploadFile = (props: any) => {
    const { onError, onChange, accept, text } = props
    const [state, setState] = useState<any>({ FileList: [] })

    const _handleOnChange = async (e: any) => {
        try {
            let imageData: any = null
            if (e.length > 0) {
                imageData = await toBase64(e[e.length - 1].blobFile)
                setState({
                    ...state,
                    image: imageData,
                    fileList: [e[e.length - 1]],
                })
            } else {
                setState({ ...state, image: null, fileList: [] })
            }
            onChange({ base64: imageData, file: e[e.length - 1] })
        } catch (e: any) {
            console.log(e)
            onError(e)
        }
    }
    return (
        <Uploader
            listType="text"
            onChange={_handleOnChange}
            fileList={state.fileList}
            onError={(e: any) => console.log('ERROR LOG', e.message)}
            autoUpload={false}
            action=""
            accept={accept || ''}
        >
            <button type="button" className="" style={{ border: '2px solid #eee', padding: '5px 12px' }}>
                <i className="zmdi zmdi-file-plus me-2 !text-gray-500"></i>
                <span className="text-gray-500">{text || 'Agregar archivo csv'}</span>
            </button>
        </Uploader>
    )
}

export default UploadFile
