import { Link } from 'react-router-dom'

interface IQrcode {
    id: string
    name: string
    description: string
    user: any
    patient: any
    qr_code: string
    image: string
    link: string
    command?: any
    start_time: string
    days_of_week: string
    order: string | number
    deleted_at: string
    token?: any
    url_token?: string
    label_token?: string
    onClose: () => void
}

const QrCodeModal = ({ image, link, token, url_token, label_token }: IQrcode) => {
    const createurlImage = (img: string) => {
        if (img?.includes('http')) {
            return img
        } else {
            return `${process.env.REACT_APP_IMAGE_API}/${img}`
        }
    }
    return (
        <div className="w-full flex justify-center items-center flex-col">
            <img src={`${createurlImage(image)}`} alt="qrcode" style={{ width: '250px', height: '250px' }} />

            <div className="w-full flex justify-center">
                <Link to={link} target="_blank" className="text-gray-700 no-underline">
                    <i className="zmdi zmdi-print me-2" />
                    Imprimir
                </Link>

                {token && token.token && (
                    <Link to={`${url_token}${token.token}`} className="text-gray-700 no-underline ms-5" target='_blank'>
                        <i className="zmdi zmdi-assignment me-2" />
                        {label_token || "Ver"}
                    </Link>
                )}
            </div>
        </div>
    )
}

export default QrCodeModal
