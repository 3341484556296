/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const CreateTaskSchema = yup.object().shape({
    name: yup
    .string()
    .matches(
      /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
      "Este campo contiene caracteres inválidos"
    )
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required("Este campo es requerido"),
    description: yup.string().required("Este campo es requerido"),
});