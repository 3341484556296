/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InputText from '../../components/InputText'
import { _pushToastMessage } from '../../helpers/messages'
import { validateYupSchema } from '../../helpers/utils'
import { CreateInvitedUserSchema } from '../../validations/user.validations'
import UserService from '../../services/users.service'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { Checkbox } from 'rsuite'

const RegisterUserWithToken = () => {
    const { token } = useParams<{ token: string }>()
    const [state, setState] = useState<any>({
        data: {
            name: '',
            email: '',
            phone_number: '',
            role_id: '',
            password: '',
            confirm_password: '',
            token: '',
        },
        validations: {},
    })

    const [terms, setTerms] = useState(false)

    const [fieldType, setFieldType] = useState('password')
    const navigate = useNavigate()
    const loader = useSelector((state: any) => state.loader)
    const dispatch = useDispatch()

    useEffect(() => {
        _getUser()
    }, [])

    const _getUser = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new UserService().getInvitation(token || '')
            setState({
                ...state,
                data: {
                    ...state.data,
                    name: response.data.name,
                    email: response.data.email,
                    role_id: response.data.role_id,
                    token: token,
                },
            })
            dispatch(setLoading({ isLoading: false }))
        } catch (err: any) {
            const errorMessage = err.response?.data?.message || 'No fue posible obtener el usuario'
            _pushToastMessage({ type: 'error', header: 'Error', text: errorMessage })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _toggleFieldType = () => {
        if (fieldType === 'password') {
            setFieldType('text')
        } else {
            setFieldType('password')
        }
    }

    const _handleOnChange = (e: any) => {
        setState({
            ...state,
            data: {
                ...state.data,
                [e.target.name]: e.target.value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const validations = await validateYupSchema(CreateInvitedUserSchema, state.data)
            setState({
                ...state,
                validations,
            })

            if (validations) return

            if(!terms) {
                _pushToastMessage({ type: 'error', header: 'Error', text: 'Debe aceptar los términos y condiciones' })
                return
            }

            dispatch(setLoading({ isLoading: true }))

            await new UserService().createInvitedUser(state.data)

            _pushToastMessage({ type: 'success', header: 'Éxito', text: 'Usuario registrado con exito' })

            dispatch(setLoading({ isLoading: false }))

            navigate('/signin')
        } catch (err: any) {
            const errorMessage = err.response?.data?.message || 'No fue posible obtener el usuario'
            _pushToastMessage({ type: 'error', header: 'Error', text: errorMessage })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleTerms = () => {
        setTerms(!terms)
    }

    return (
        <div className="min-h-[100vh] flex justify-center items-center bg-gray-200 text-gray-700">
            <div className="rounded-lg shadow-md bg-white px-8 pt-10 pb-4 md:min-w-[320px] max-w-[90%]">
                <div className="text-center text-[30px] font-bold">Bienvenido</div>

                <div className="text-center font-bold mt-3 text-[24px]">Lectogram</div>

                <div className="mt-2">
                    <InputText
                        name="name"
                        label="Nombre"
                        value={state.data.name}
                        validations={state.validations}
                        onChange={_handleOnChange}
                    />
                </div>

                <div className="mt-2">
                    <InputText
                        name="email"
                        value={state.data.email}
                        disabled
                        label="Email"
                        validations={state.validations}
                        onChange={_handleOnChange}
                    />
                </div>

                <div className="mt-2">
                    <InputText
                        name="phone_number"
                        label="Teléfono"
                        value={state.data.phone_number}
                        validations={state.validations}
                        onChange={_handleOnChange}
                    />
                </div>

                <div className="relative mt-2">
                    <button
                        className={`p-0 m-0 absolute right-2 text-gray-500 ${
                            state.validations?.password ? 'bottom-5' : 'bottom-1'
                        }`}
                        onClick={_toggleFieldType}
                    >
                        {fieldType === 'password' ? (
                            <i className="zmdi zmdi-eye"></i>
                        ) : (
                            <i className="zmdi zmdi-eye-off"></i>
                        )}
                    </button>
                    <InputText
                        name="password"
                        label="Contraseña"
                        value={state.data.password}
                        validations={state.validations}
                        type={fieldType}
                        onChange={_handleOnChange}
                    />
                </div>

                <div className="relative mt-2">
                    <button
                        className={`p-0 m-0 absolute right-2 text-gray-500 ${
                            state.validations?.confirm_password ? 'bottom-5' : 'bottom-1'
                        }`}
                        onClick={_toggleFieldType}
                    >
                        {fieldType === 'password' ? (
                            <i className="zmdi zmdi-eye"></i>
                        ) : (
                            <i className="zmdi zmdi-eye-off"></i>
                        )}
                    </button>
                    <InputText
                        name="confirm_password"
                        label="Confirmar contraseña"
                        value={state.data.confirm_password}
                        validations={state.validations}
                        type={fieldType}
                        onChange={_handleOnChange}
                    />
                </div>

                <div className="relative mt-2 text-[12px]">
                    <Checkbox onChange={_handleTerms} checked={terms} value={0}>
                        He leído y acepto las{' '}
                        <a href="/terms-and-conditions" target="_blank">
                            Términos de uso
                        </a>{' '}
                        y{' '}
                        <a href="/privacy-policies" target="_blank">
                            Políticas de privacidad
                        </a>
                    </Checkbox>
                </div>

                <div className="text-center mt-8 mb-5">
                    <button
                        className="border-b-blue-300 border-b-2 px-2 rounded-sm hover:text-blue-300"
                        onClick={_handleSubmit}
                        disabled={loader.isLoading}
                    >
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RegisterUserWithToken
