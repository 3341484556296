import { Table, Whisper } from 'rsuite'
import WhisperComponent from '../components/WhisperComponent'
import { useDispatch, useSelector } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { orderData, sleep } from '../helpers/utils'
import DraggableRow from '../components/DraggableRow'
import { setLoading } from '../redux/slicers/loader'
import { _pushToastMessage } from '../helpers/messages'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useRef, useState } from 'react'
import ConfirmationModal from '../components/ConfirmationModal'
import { useParams } from 'react-router-dom'
import { WhisperMenu } from '../components/WhisperMenu'
import { _actionMenuPreLoadedTaskTableSpeaker } from '../pages/Tasks/tableSpeaker'
import PreLoadedTaskService from '../services/pre_loaded_task.service'

interface ITasksTable {
    data: any
    setData: (data: any) => void
    onEditTask: (data: any) => void
}
const PreLoadedTasksTable = ({ data, setData, onEditTask }: ITasksTable) => {
    const { loader } = useSelector((state: any) => state)

    const params = useParams()

    const [modals, setModals] = useState<any>({
        routineDetailsModal: {
            open: false,
            data: {
                name: '',
                description: '',
                user: {},
                patient: {},
                qr_code: '',
                command: {},
                start_time: '',
                days_of_week: '',
                order: '',
                deleted_at: '',
            },
        },
        confirmationModal: {
            open: false,
            title: 'Confirmación',
            data: {},
        },
    })

    const [readingAll, setReadingAll] = useState(false)

    const taskRef = useRef<any>(null)

    const dispatch = useDispatch()

    const isTouchDevice = () => {
        if ('ontouchstart' in window) {
            return true
        }
        return false
    }

    // Assigning backend based on touch support on the device
    const backendForDND: any = isTouchDevice() ? TouchBackend : HTML5Backend

    const handleDragRow = async (source_id: any, target_id: any) => {
        try {
            if (source_id !== target_id) {
                dispatch(setLoading({ isLoading: true }))
                await new PreLoadedTaskService().reorderTasks({ source_id, target_id })
                const reordedData = orderData(data.tasks, source_id, target_id)
                data.tasks = reordedData
                setData({ ...data })
                dispatch(setLoading({ isLoading: false }))
            }
        } catch (error) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'No se pudo reordenar la rutina',
            })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleOnClickTask = (e: any) => {
        e.preventDefault()
        const task = data.tasks.find((item: any) => item.id === parseInt(e.currentTarget.id))
        console.log(task)
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    const _handleConfirmationConfirmationModal = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { id } = modals.confirmationModal?.data
            await new PreLoadedTaskService().toggleTask(id)
            _handleToggleModal('confirmationModal')
            _pushToastMessage({
                type: 'success',
                header: 'Exito',
                text: 'Tarea eliminada con exito',
            })
            dispatch(setLoading({ isLoading: false }))
            const response = await new PreLoadedTaskService().getTasks({ id: params.id })
            setData(response.data)
        } catch (error) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'No se pudo eliminar la rutina',
            })
            dispatch(setLoading({ isLoading: false }))
            _handleToggleModal('confirmationModal')
        }
    }

    const _readDescription = (task: any) => {
        if (taskRef?.current?.speech?.speaking) {
            if (taskRef.current.task.id === task.id) {
                const targetTask = data.tasks.find((item: any) => item.id === task.id)
                if (targetTask) {
                    targetTask.voice.isPlaying = true
                    setData({ ...data })
                    window.speechSynthesis.resume()
                }
                return
            } else {
                const taskTarget = data.tasks.find((item: any) => item.id === taskRef?.current?.task?.id)
                if (taskTarget) {
                    taskTarget.voice.isPlaying = false
                    setData({ ...data })
                    window.speechSynthesis.cancel()
                }
            }
        }

        const speech = new SpeechSynthesisUtterance()
        speech.onend = () => {
            const taskTarget = data.tasks.find((item: any) => item.id === task.id)
            if (taskTarget) taskTarget.voice.isPlaying = false
            setData({ ...data })
        }
        speech.onstart = () => {
            const taskTarget = data.tasks.find((item: any) => item.id === task.id)
            if (taskTarget) taskTarget.voice.isPlaying = true
            setData({ ...data })
        }
        speech.text = task?.description || ''
        speech.lang = task?.taskconfig?.lang || 'ES-es'
        speech.volume = task?.taskconfig?.volume || 1
        speech.pitch = task?.taskconfig?.pitch || 1
        speech.rate = task?.taskconfig?.rate || 1

        window.speechSynthesis.speak(speech)

        taskRef.current = {
            task: task,
            speech: window.speechSynthesis,
        }
    }

    const _readDescriptionPromisse = (task: any) => {
        return new Promise((resolve, reject) => {
            try {
                const speech = new SpeechSynthesisUtterance()
                speech.onend = () => {
                    const taskTarget = data.tasks.find((item: any) => item.id === task.id)
                    if (taskTarget) taskTarget.voice.isPlaying = false
                    setData({ ...data })
                    resolve('ended')
                }

                speech.onstart = () => {
                    const taskTarget = data.tasks.find((item: any) => item.id === task.id)
                    if (taskTarget) taskTarget.voice.isPlaying = true
                    setData({ ...data })
                }

                speech.text = task?.description || ''
                speech.lang = task?.taskconfig?.lang || 'ES-es'
                speech.volume = task?.taskconfig?.volume || 1
                speech.pitch = task?.taskconfig?.pitch || 1
                speech.rate = task?.taskconfig?.rate || 1

                taskRef.current = task

                window.speechSynthesis.speak(speech)
            } catch (e) {
                reject(e)
            }
        })
    }

    const _readAllTasks = async () => {
        window.speechSynthesis.cancel()
        data.tasks.forEach((task: any) => {
            task.voice.isPlaying = false
        })

        setData({ ...data })

        setReadingAll(true)
        for (const task of data.tasks) {
            if (task.deleted_at === null) {
                await _readDescriptionPromisse(task)
                await sleep(task.taskconfig?.delay ? task.taskconfig?.delay * 1000 : 500)
            }
        }
        setReadingAll(false)
    }

    const _handleSelectMenu = (payload: any) => {
        console.log(payload)
        switch (payload.option) {
            case 1:
                _handleToggleModal('confirmationModal', 'delete', payload.payload?.data)
                break
            case 2:
                onEditTask(payload.payload?.data)
                break
            case 3:
                _readDescription(payload.payload?.data)
                break
            default:
                break
        }
    }

    const Actions = (payload: any) => {
        const { data } = payload
        const _speaker: any = _actionMenuPreLoadedTaskTableSpeaker(data, _handleSelectMenu)
        return (
            <Whisper
                placement="auto"
                trigger="click"
                speaker={(whisper_payload: any, ref: any) => WhisperMenu(whisper_payload, ref, _speaker)}
            >
                <button className="w-full">
                    <i className="zmdi zmdi-more cursor-pointer"></i>
                </button>
            </Whisper>
        )
    }

    const pauseSpeech = (task: any) => {
        const taskTarget = data.tasks.find((item: any) => item.id === task.id)
        if (taskTarget) taskTarget.voice.isPlaying = false
        setData({ ...data })
        window.speechSynthesis.pause()
        taskRef.current = {
            task: task,
            speech: window.speechSynthesis,
        }
    }

    const stopSpeech = () => {
        window.speechSynthesis.cancel()
        setReadingAll(false)
        data.tasks.forEach((task: any) => {
            task.voice.isPlaying = false
        })
        setData({ ...data })
    }

    return (
        <div className="w-full">
            <ConfirmationModal
                open={modals.confirmationModal.open}
                title={modals.confirmationModal?.title}
                onClose={() => _handleToggleModal('confirmationModal')}
                onConfirm={_handleConfirmationConfirmationModal}
            >
                <p>
                    ¿Está seguro que desea {modals.confirmationModal?.data?.deleted_at ? 'activar' : 'desactivar'} esta
                    tarea?
                </p>
            </ConfirmationModal>

            <DndProvider backend={backendForDND}>
                <Table
                    autoHeight
                    rowHeight={70}
                    className="w-[100%]"
                    data={data.tasks}
                    locale={{ emptyMessage: 'No hay tareas' }}
                    loading={loader.isLoading}
                    rowKey="id"
                    renderRow={(children, rowData) => {
                        return rowData ? (
                            <DraggableRow key={rowData.id} rowData={rowData} id={rowData.id} onDrag={handleDragRow}>
                                {children}
                            </DraggableRow>
                        ) : (
                            children
                        )
                    }}
                >
                    <Table.Column flexGrow={1} minWidth={50}>
                        <Table.HeaderCell align="center">ID</Table.HeaderCell>
                        <Table.Cell>
                            {(item) => {
                                return (
                                    <WhisperComponent text={`${item.id}`}>
                                        <span className="size-08">{`${item.id}`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Nombre Tarea</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent text={`${item.name}`}>
                                        <a
                                            href="/"
                                            className="text-blue-400 hover:text-blue-400 no-underline active:no-underline"
                                            onClick={_handleOnClickTask}
                                            id={`${item.id}`}
                                        >
                                            <span className="size-08">{`${item.name}`}</span>
                                        </a>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Descripcion</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`${item.description}`}>
                                        <span className="size-08">{`${item.description}`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">
                            <WhisperComponent capitalizeOff text={`Escuchar todas`}>
                                <button onClick={readingAll ? stopSpeech : _readAllTasks}>
                                    {`Escuchar `}
                                    {readingAll ? (
                                        <i className="zmdi zmdi-stop ms-2 text-[15px]"></i>
                                    ) : (
                                        <i className="zmdi zmdi-play-circle ms-2 text-[15px]"></i>
                                    )}
                                </button>
                            </WhisperComponent>
                        </Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent capitalizeOff text={`Escuchar`}>
                                        <button
                                            disabled={readingAll}
                                            onClick={
                                                item?.voice?.isPlaying
                                                    ? () => pauseSpeech(item)
                                                    : () => _readDescription(item)
                                            }
                                        >
                                            {item?.voice?.isPlaying ? (
                                                <i className="zmdi zmdi-pause-circle ms-2 text-[20px] text-blue-400"></i>
                                            ) : (
                                                <i className="zmdi zmdi-play-circle ms-2 text-[20px] text-blue-400"></i>
                                            )}
                                        </button>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Status</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle">
                            {(item) => {
                                return (
                                    <WhisperComponent
                                        capitalizeOff
                                        text={`${!item.deleted_at ? 'Activo' : 'Desactivado'}`}
                                    >
                                        <span className="size-08">{`${
                                            !item.deleted_at ? 'Activo' : 'Desactivado'
                                        }`}</span>
                                    </WhisperComponent>
                                )
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} minWidth={120}>
                        <Table.HeaderCell align="center">Acciones</Table.HeaderCell>
                        <Table.Cell verticalAlign="middle" align="center">
                            {(item) => {
                                return <Actions data={item} />
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
            </DndProvider>
        </div>
    )
}

export default PreLoadedTasksTable
