import { Table, Whisper } from 'rsuite'
import WhisperComponent from '../../../components/WhisperComponent'
import { TableHeader } from './TableHeader'
import IRole from '../../../interfaces/IRole'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useState } from 'react'
import { _actionMenuSpeaker } from './speaker'
import { _pushToastMessage } from '../../../helpers/messages'
import { WhisperMenu } from '../../../components/WhisperMenu'
import ModalComponent from '../../../components/ModalComponent'
import UserList from './Userlist'
import AssociatePatientToCaregiver from '../../../modals/User/AssociatePatientToCaregiver'
import AssociatePatientToTutor from '../../../modals/User/AssociatePatientToTutor'
import Permitions from '../../../modals/User/Permitions'
import Caregivers from './Caregivers'

interface IUserTable {
    data: any[]
    onUpdate: (data: any) => void
    onDelete: (data: any) => void
    onChangePassword: (data: any) => void
    sortData: (data: any) => void
    updateTableData: (data: any) => void
    getData: () => void
}

const UserTable = ({ data, onUpdate, onDelete, onChangePassword, sortData, updateTableData, getData }: IUserTable) => {
    const { loader } = useSelector((state: any) => state)

    const [sortColumn, setSortColumn] = useState()
    const [sortType, setSortType] = useState()
    const [modals, setModals] = useState<any>({
        userListModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        associatePatientToCaregiver: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        associatePatientToTutor: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        permitionsModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        caregiversModal: {
            open: false,
            action: '',
            title: '',
            data: [],
        },
    })

    const GetRole = ({ userRoles }: { userRoles: IRole[] }) => {
        const roles = userRoles?.map((role: IRole) => role.label).join(', ')
        return <>{roles}</>
    }

    const _handleSelectMenu = (eventKey: any) => {
        switch (eventKey.option) {
            case 0:
                break
            case 1:
                onDelete(eventKey.payload?.data)
                break
            case 2:
                onUpdate(eventKey.payload?.data)
                break
            case 3:
                onChangePassword(eventKey.payload?.data)
                break
            case 4:
                setModals({
                    ...modals,
                    associatePatientToCaregiver: {
                        open: true,
                        action: 'Ver',
                        title: 'Cuidadores',
                        data: eventKey.payload?.data,
                    },
                })
                break
            case 5:
                setModals({
                    ...modals,
                    userListModal: { open: true, action: 'Ver', title: 'Usuarios', data: eventKey.payload?.data },
                })
                break
            case 6:
                setModals({
                    ...modals,
                    associatePatientToTutor: {
                        open: true,
                        action: 'Ver',
                        title: 'Usuarios',
                        data: eventKey.payload?.data,
                    },
                })
                break
            case 7:
                setModals({
                    ...modals,
                    permitionsModal: { open: true, action: 'update', title: 'Permisos', data: eventKey.payload?.data },
                })
                break

            default:
                _pushToastMessage({ type: 'warning', header: 'Aviso', text: 'Función disponible pronto' })
        }
    }

    const _sortTableData = (sortColumn: any, sortType: any) => {
        setSortColumn(sortColumn)
        setSortType(sortType)
        sortData({ sortColumn, sortType })
    }

    const Actions = (payload: any) => {
        const { data } = payload
        const _speaker: any = _actionMenuSpeaker(data, _handleSelectMenu)
        return (
            <Whisper
                placement="auto"
                trigger="click"
                speaker={(whisper_payload: any, ref: any) => WhisperMenu(whisper_payload, ref, _speaker)}
            >
                <i className="zmdi zmdi-more cursor-pointer"></i>
            </Whisper>
        )
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    const _updatePatientData = (patient: any) => {
        setModals({
            ...modals,
            associatePatientToCaregiver: { ...modals.associatePatientToCaregiver, data: patient, open: false },
        })
        getData()
    }

    const _updateTutorState = (tutor: any) => {
        setModals({ ...modals, associatePatientToTutor: { ...modals.associatePatientToTutor, data: tutor } })
        const tutorUser = data.find((user: any) => user.id === tutor.id)
        tutorUser.list_of_patients_to_tutor = tutor.list_of_patients_to_tutor
        updateTableData([...data])
    }

    return (
        <div className="w-full mt-5">
            <ModalComponent
                size="lg"
                open={modals?.userListModal?.open}
                handleClose={() => _handleToggleModal('userListModal')}
                title="Usuarios"
            >
                <UserList data={modals?.userListModal?.data} handleClose={() => _handleToggleModal('userListModal')} />
            </ModalComponent>

            <ModalComponent
                size="lg"
                open={modals?.caregiversModal?.open}
                handleClose={() => _handleToggleModal('caregiversModal')}
                title="Cuidadores"
                >
                    <Caregivers data={modals?.caregiversModal?.data} handleClose={() => _handleToggleModal('caregiversModal')}/>
                </ModalComponent>

            <ModalComponent
                size="lg"
                open={modals?.associatePatientToCaregiver?.open}
                handleClose={() => _handleToggleModal('associatePatientToCaregiver')}
                title="Asignar o eliminar cuidador"
            >
                <AssociatePatientToCaregiver
                    patient={modals?.associatePatientToCaregiver?.data}
                    updatePatientData={_updatePatientData}
                />
            </ModalComponent>

            <ModalComponent
                size="lg"
                open={modals?.associatePatientToTutor?.open}
                handleClose={() => _handleToggleModal('associatePatientToTutor')}
                title="Asignar o eliminar usuarios"
            >
                <AssociatePatientToTutor
                    tutor={modals?.associatePatientToTutor?.data}
                    updateTutorData={_updateTutorState}
                />
            </ModalComponent>

            <ModalComponent
                size="md"
                open={modals?.permitionsModal?.open}
                handleClose={() => _handleToggleModal('permitionsModal')}
                title="Permisos"
            >
                <Permitions
                    open={modals?.permitionsModal?.open}
                    handleClose={() => _handleToggleModal('permitionsModal')}
                    data={modals?.permitionsModal?.data}
                    setData={updateTableData}
                />
            </ModalComponent>

            <Table
                data={data}
                autoHeight
                rowHeight={80}
                locale={{ emptyMessage: 'No hay usuarios' }}
                loading={loader.isLoading}
                onSortColumn={_sortTableData}
                sortColumn={sortColumn}
                sortType={sortType}
            >
                {TableHeader &&
                    TableHeader.map((column: any, index: any) => (
                        <Table.Column
                            align={column.align}
                            flexGrow={column.flexGrow}
                            key={`table-column-${index}`}
                            minWidth={120}
                            sortable
                        >
                            <Table.HeaderCell>
                                <span className="bold" style={{ textTransform: 'capitalize' }}>
                                    {column.label}
                                </span>
                            </Table.HeaderCell>

                            <Table.Cell
                                style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}
                                dataKey={column.key}
                            >
                                {(rowData) => {
                                    switch (column.key) {
                                        case 'name':
                                            return (
                                                <WhisperComponent text={`${rowData?.name}`} capitalizeOff align="left">
                                                    <span>{`${rowData?.name}`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'email':
                                            return (
                                                <WhisperComponent text={`${rowData?.email}`} capitalizeOff align="left">
                                                    <span>{`${rowData?.email}`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'roles':
                                            return (
                                                <WhisperComponent text={<GetRole userRoles={rowData?.roles} />}>
                                                    <span className="size-08">
                                                        <GetRole userRoles={rowData?.roles} />
                                                    </span>
                                                </WhisperComponent>
                                            )
                                        case 'deleted_at':
                                            return (
                                                <WhisperComponent text={!rowData.deleted_at ? 'Activo' : 'Inactivo'}>
                                                    <span className="size-08">
                                                        {!rowData.deleted_at ? 'Activo' : 'Inactivo'}
                                                    </span>
                                                </WhisperComponent>
                                            )
                                        case 'phone_number':
                                            return (
                                                <WhisperComponent text={`(+56) ${rowData.phone_number}`}>
                                                    <span className="size-08">{`(+56) ${rowData.phone_number}`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'created_at':
                                            return (
                                                <WhisperComponent
                                                    text={`${moment(rowData.created_at).format('DD-MM-YYYY')}`}
                                                >
                                                    <span className="size-08">{`${moment(rowData.created_at).format(
                                                        'DD-MM-YYYY'
                                                    )}`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'caregiver':
                                            return (
                                                <WhisperComponent text={`Ver lista de cuidadores`}>
                                                    {rowData.roles?.find((role: any) => role.value !== 'paciente') && (
                                                        <>-</>
                                                    )}
                                                    {rowData.roles?.find((role: any) => role.value === 'paciente') && (
                                                        <button
                                                            className="underline text-blue-400"
                                                            onClick={() => _handleToggleModal("caregiversModal", "Ver", rowData.list_of_caregivers_to_patient)}
                                                        >
                                                            Ver
                                                        </button>
                                                    )}
                                                </WhisperComponent>
                                            )
                                        default:
                                            return (
                                                <WhisperComponent text={rowData[column.key]}>
                                                    <div className="w-full">{rowData[column.key]}</div>
                                                </WhisperComponent>
                                            )
                                    }
                                }}
                            </Table.Cell>
                        </Table.Column>
                    ))}
                <Table.Column align="center" verticalAlign="middle">
                    <Table.HeaderCell>{'Acciones'}</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            return <Actions data={rowData} />
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </div>
    )
}

export default UserTable
