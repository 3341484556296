/* eslint-disable react-hooks/exhaustive-deps */
import ModalComponent from '../../components/ModalComponent'
import { useEffect, useState } from 'react'
import { validateYupSchema } from '../../helpers/utils'
import { _pushToastMessage } from '../../helpers/messages'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import ErrorValidation from '../../components/ErrorValidation'
import { AttachRoomSchema } from '../../validations/room.validations'
import { SelectPicker, TagPicker } from 'rsuite'
import PlaceService from '../../services/places.service'
import RoutineService from '../../services/routine.service'

interface IRoutinePlaceForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    getData: () => void
}
const RoutinePlaceForm = ({ handleClose, action, open, data, getData }: IRoutinePlaceForm) => {
    const dispatch = useDispatch()
    const { loader } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: {
            place_id: "",
            rooms_id: [],
        },
        validations: {},
    })
    const [places, setPlaces] = useState([])
    const [rooms, setRooms] = useState([])

    useEffect(() => {
        if (!open) return

        _getPlaces()

        setState({
            ...state,
            validations: {},
            data: {
                place_id: "",
                rooms_id: [],
            },
        })
    }, [open])

    const _getPlaces = async () => {
        try {
            const { data: placeList } = await new PlaceService().getSelectPlaces()
            setPlaces(placeList)
        } catch (err: any) {
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    const _handleRSuiteOnChange = async (value: any, name: string) => {
        if (name === 'place_id') {
            const { data: roomList } = await new PlaceService().getSelectRooms(value)
            setRooms(roomList)
        }
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const schema = AttachRoomSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            const payload: any = {
                routine_id: data.id,
                place_id: state.data.place_id,
                rooms_id: state.data.rooms_id,
            }

            await new RoutineService().attachRoom(payload)

            dispatch(setLoading({ isLoading: false }))

            handleClose()
            getData()

            const message = 'Estancia asociada con exito'
            _pushToastMessage({ type: 'success', header: 'Exito', text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    return (
        <ModalComponent open={open} title={`Agregar estancia a la rutina`} handleClose={handleClose}>
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Hogar</span>
                    <SelectPicker
                        data={places}
                        onChange={(data) => _handleRSuiteOnChange(data, 'place_id')}
                        className="w-full"
                        placeholder="Selecciona el hogar"
                        value={state.data?.place_id}
                        searchable
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="place_id"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Estancias</span>
                    <TagPicker
                        data={rooms}
                        onChange={(data) => _handleRSuiteOnChange(data, 'rooms_id')}
                        className="w-full"
                        placeholder="Selecciona las estancias"
                        searchable
                        value={state.data?.rooms_id}
                        labelKey="name"
                        valueKey="id"
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="rooms_id"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default RoutinePlaceForm
