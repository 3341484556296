/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const CreatePlaceSchema = yup.object().shape({
    name: yup
        .string()
        .matches(
            /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
            'Este campo contiene caracteres inválidos'
        )
        .max(255, 'Este campo acepta un máximo de 255 caracteres')
        .required('Este campo es requerido'),
    patients_id: yup.array().min(1, 'Este campo es requerido').required('Este campo es requerido'),
})

export type CreatePlaceType = yup.InferType<typeof CreatePlaceSchema>
