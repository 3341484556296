import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    isLogged: false,
    profile: {},
    tokens: {
      accessToken: "",
    },
  },
  reducers: {
    signin: (state, action) => {
        state.isLogged = true;
        state.profile = action.payload.profile;
        state.tokens = action.payload.tokens;
    },
    signout: (state, action) => {
        state.isLogged = false;
        state.profile = {};
        state.tokens = {
          accessToken: "",
        };
    }
  },
});

export const { signin, signout } = sessionSlice.actions;

export default sessionSlice.reducer;
