/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useParams } from 'react-router-dom'
import TaskService from '../../services/task.service'
import { useEffect, useState } from 'react'
import { _pushToastMessage } from '../../helpers/messages'
import { sleep } from '../../helpers/utils'

const RoutineDetails = () => {
    const [state, setState] = useState<any>({})

    const params = useParams()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            const response = await new TaskService().getTasks({ id: params.id })
            setState(response.data)
            await sleep(500)
            window.print();
        } catch (e: any) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No fue posible cargar la rutina' })
        }
    }
    
    const createurlImage = (img: string) => {
        if (img?.includes('http')) {
            return img
        } else {
            return `${process.env.REACT_APP_IMAGE_API}/${img}`
        }
    }

    return (
        <div className="flex w-full h-full justify-center items-center flex-col">
            <div className="flex justify-center items-center flex-col border-2 rounded-sm p-5">
                <div>
                    <img
                        src={createurlImage(state.qr_code)}
                        alt="qrcode"
                        style={{ width: '250px', height: '250px' }}
                    />
                </div>
                <div>{state.name}</div>
                <div>Hora inicio: {state.start_time}</div>
                <div className="print:hidden mt-3">
                    <Link to="/routines">
                        <i className="zmdi zmdi-arrow-left me-2"></i>
                        Volver
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default RoutineDetails
