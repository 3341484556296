/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import HomeHeader from './Header'
import UserForm from '../../forms/UserForm'
import UserTable from './UserTable'
import UserService from '../../services/users.service'
import ChangePassword from '../../forms/UserForm/ChangePassword'
import ConfirmationModal from '../../components/ConfirmationModal'
import { _pushToastMessage } from '../../helpers/messages'
import InvitationForm from '../../forms/UserForm/InvitationForm'
import MassiveLoadForm from '../../forms/UserForm/MassiveLoadForm'
import FilterUserForm from '../../forms/UserForm/FilterUserForm'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { sortData } from '../../helpers/utils'
import PaginationComponent from '../../components/PaginationComponent'

const Users = () => {
    const User = new UserService()
    const dispatch = useDispatch()

    const [modals, setModals] = useState<any>({
        userFormModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        changePasswordModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        confirmationModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        invitationFormModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        massiveLoadForm: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        filterUserForm: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
    })

    const [data, setData] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    useEffect(() => {
        _getUsers()
    }, [])

    const _getUsers = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { data } = await User.getUsers()
            data.data = sortData(data.data, data.sortColumn, data.sortType)
            setData(data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            dispatch(setLoading({ isLoading: true }))
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al obtener los usuarios' })
        }
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    const _handleToggleUserStatus = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { data } = modals.confirmationModal
            await User.disableUser(data.id)
            _getUsers()
            _pushToastMessage({
                type: 'success',
                header: 'Éxito',
                text: `Usuario ${data.deleted_at ? 'activado' : 'desactivado'} correctamente`,
            })
            _handleToggleModal('confirmationModal')
        } catch (e: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: e?.response?.data?.message || 'Ocurrió un error actualizar el usuario',
            })
        }
    }

    const _sorData = ({ sortColumn, sortType }: any) => {
        const sortedData = sortData(data.data, sortColumn, sortType)

        setData({
            ...data,
            data: sortedData,
        })
    }

    const _updateTableData = (payload: any) => {
        setData({
            ...data,
            data: payload,
        })
    }

    return (
        <div>
            <UserForm
                handleClose={() => _handleToggleModal('userFormModal')}
                open={modals.userFormModal.open}
                action={modals.userFormModal.action}
                data={modals.userFormModal.data}
                getUsers={_getUsers}
            />

            <ChangePassword
                handleClose={() => _handleToggleModal('changePasswordModal')}
                open={modals.changePasswordModal.open}
                data={modals.changePasswordModal.data}
                getUsers={_getUsers}
            />
            
            <ConfirmationModal
                open={modals.confirmationModal.open}
                title={modals.confirmationModal?.data?.deleted_at ? 'Activar usuario' : 'Desactivar usuario'}
                onClose={() => _handleToggleModal('confirmationModal')}
                onConfirm={_handleToggleUserStatus}
            >
                <p>
                    ¿Está seguro que desea {modals.confirmationModal?.data?.deleted_at ? 'activar' : 'desactivar'} este
                    usuario?
                </p>
            </ConfirmationModal>

            <InvitationForm
                open={modals.invitationFormModal.open}
                handleClose={() => _handleToggleModal('invitationFormModal')}
            />
            <MassiveLoadForm
                open={modals.massiveLoadForm.open}
                handleClose={() => _handleToggleModal('massiveLoadForm')}
            />
            <FilterUserForm
                open={modals.filterUserForm.open}
                handleClose={() => _handleToggleModal('filterUserForm')}
                handleFilter={setData}
            />

            <HomeHeader handleOpenModal={_handleToggleModal} updateTableData={setData} />

            <UserTable
                onUpdate={(data: any) => _handleToggleModal('userFormModal', 'update', data)}
                onChangePassword={(data: any) => _handleToggleModal('changePasswordModal', 'update', data)}
                onDelete={(data: any) => _handleToggleModal('confirmationModal', 'delete', data)}
                data={data.data}
                sortData={_sorData}
                updateTableData={_updateTableData}
                getData={_getUsers}
            />

            <div className="w-full pb-10 mt-5">
                <PaginationComponent pagination={data} onRequestEnd={setData} />
            </div>
        </div>
    )
}

export default Users
