import { Modal } from "rsuite";
import { IModalComponent } from "../../interfaces/IModalComponent";

interface ModalComponentProps extends IModalComponent {
    handleClose: () => void
    size?: "xs" | "sm" | "md" | "lg" | "xl"
}

const ModalComponent = ({ open, title, children, handleClose, size }: ModalComponentProps) => {
    return (
        <Modal open={open} onClose={handleClose} size={size || "sm"}>
            <Modal.Header>
                <Modal.Title> {title} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default ModalComponent;