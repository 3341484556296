import { SelectPicker } from 'rsuite'
import UploadFile from '../UploadFiles'

const SelectWithCategoryImage = ({ onChange, data, value }: any) => {
    const Box = ({ children }: any) => {
        return <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>{children}</div>
    }

    const renderMenuItem = (label: any, item: any) => {
        return (
            <Box>
                {item?.path && (
                    <img
                        src={`${process.env.REACT_APP_IMAGE_API}${item?.path}`}
                        alt="icon"
                        style={{ width: '25px' }}
                    />
                )}
                <span>{label}</span>
            </Box>
        )
    }

    const renderValue = (value: any, item: any) => {
        return (
            <Box>
                {item?.path && (
                    <img
                        src={`${process.env.REACT_APP_IMAGE_API}${item?.path}`}
                        alt="icon"
                        style={{ width: '25px' }}
                    />
                )}
                {item?.name}
            </Box>
        )
    }

    return (
        <>
            <div className="mb-2 w-full">
                <label className="text-[12px]">Imagen del codigo QR</label>
                <SelectPicker
                    data={data}
                    labelKey="name"
                    valueKey="id"
                    placeholder="Selecciona una imagen"
                    style={{ width: '100%' }}
                    renderMenuItem={(label, item) => renderMenuItem(label, item)}
                    renderValue={(value, item) => renderValue(value, item)}
                    value={value}
                    onChange={(value: any) => onChange(value, 'media_id')}
                />
            </div>

            {value === 'other' && (
                <div className="mb-2 w-full">
                    <label className="text-[12px]">Ingresa una imagen para el codigo QR</label>
                    <UploadFile
                        text="Agregar imagen al codigo QR"
                        onChange={(data: any) => onChange(data.base64, 'logo_img')}
                        accept=".png"
                    />
                </div>
            )}
        </>
    )
}

export default SelectWithCategoryImage
