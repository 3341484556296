/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import ModalComponent from '../../components/ModalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import TaskService from '../../services/task.service'
import { useParams } from 'react-router-dom'
interface ITaskConfigForm {
    open: boolean
    title: string
    handleClose: () => void
    setData: (data: any) => void
    data: any
}

const TaskConfigForm = ({ open, title, handleClose, data, setData }: ITaskConfigForm) => {
    const [state, setState] = useState({
        delay: 0,
        pitch: 0,
        volume: 0,
        rate: 0,
    })

    const params = useParams()

    const { isLoading } = useSelector((state: any) => state.loader)

    const dispatch = useDispatch()

    useEffect(() => {
        if (open) {
            setState({
                delay: Number(data?.delay),
                pitch: Number(data?.pitch),
                volume: Number(data?.volume),
                rate: Number(data?.rate),
            })
        }
    }, [open])

    const _handleOnChange = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        })
    }

    const _handleSubmit = async (e: any) => {
        e.preventDefault()
        try {
            dispatch(setLoading({ isLoading: true }))
            await new TaskService().updateTasksConfigs(data.id, state)
            handleClose()
            const response = await new TaskService().getTasks({ id: params.id })
            response.data.tasks = response.data.tasks.map((item: any) => {
                item.voice = {
                    text: item.name,
                    lang: 'es',
                    isPlaying: false,
                }
                return item
            })
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'success', header: 'Éxito', text: 'Configuración actualizada' })
        } catch (e) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Error al actualizar la configuración' })
        }
    }

    return (
        <ModalComponent open={open} title={title} handleClose={handleClose}>
            <form onSubmit={_handleSubmit} className="w-full">
                <div className="w-full flex flex-wrap">
                    <div className="w-[50%] mb-5 flex flex-col px-4">
                        <label htmlFor="">Paso ({state.pitch})</label>
                        <input
                            type="range"
                            name="pitch"
                            id=""
                            min="0.5"
                            max="2"
                            step="0.1"
                            value={state.pitch}
                            onChange={_handleOnChange}
                        />
                    </div>
                    <div className="w-[50%] mb-5 flex flex-col px-4">
                        <label htmlFor="">Volumen ({state.volume})</label>
                        <input
                            type="range"
                            name="volume"
                            id=""
                            min="0.5"
                            max="2"
                            step="0.1"
                            value={state.volume}
                            onChange={_handleOnChange}
                        />
                    </div>
                    <div className="w-[50%] mb-5 flex flex-col px-4">
                        <label htmlFor="">Tasa ({state.rate})</label>
                        <input
                            type="range"
                            name="rate"
                            id=""
                            min="0.5"
                            max="2"
                            step="0.1"
                            value={state.rate}
                            onChange={_handleOnChange}
                        />
                    </div>
                    <div className="w-[50%] mb-5 flex flex-col px-4">
                        <label htmlFor="">Delay ({state.delay} seg)</label>
                        <input
                            type="range"
                            name="delay"
                            id=""
                            min="0"
                            max="300"
                            step="1"
                            value={state.delay}
                            onChange={_handleOnChange}
                        />
                    </div>

                    <div className="w-full text-end">
                        <button className="button me-3" type="button" onClick={handleClose} disabled={isLoading}>
                            Cerrar
                        </button>
                        <button className="button" type="submit" disabled={isLoading}>
                            Guardar
                        </button>
                    </div>
                </div>
            </form>
        </ModalComponent>
    )
}

export default TaskConfigForm

/**
 * delay: 0
 * pitch: 0
 * volume: 0
 * rate: 0
 *
 */
