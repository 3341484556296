/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import HomeHeader from './Header'
import PlaceTable from './PlaceTable'
import { _pushToastMessage } from '../../helpers/messages'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { sortData } from '../../helpers/utils'
import PaginationComponent from '../../components/PaginationComponent'
import PlaceForm from '../../forms/PlaceForm/PlaceForm'
import PlaceService from '../../services/places.service'
import ConfirmationModal from '../../components/ConfirmationModal'
import ModalComponent from '../../components/ModalComponent'
import QrCodeModal from '../../modals/QrCodeModal'

const Places = () => {
    const dispatch = useDispatch()

    const [modals, setModals] = useState<any>({
        placeFormModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        confirmationModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        qrcodeModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
    })

    const [data, setData] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { data } = await new PlaceService().getPlaces()
            data.data = sortData(data.data, data.sortColumn, data.sortType)
            setData(data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            dispatch(setLoading({ isLoading: true }))
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al obtener los hogares' })
        }
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal].open,
            },
        })
    }

    const _sorData = ({ sortColumn, sortType }: any) => {
        const sortedData = sortData(data.data, sortColumn, sortType)

        setData({
            ...data,
            data: sortedData,
        })
    }

    const _handleTogglePlaceStatus = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { data: place } = modals.confirmationModal
            if (place.deleted_at) {
                await new PlaceService().restorePlace(place.id)
            } else {
                await new PlaceService().deletePlace(place.id)
            }
            dispatch(setLoading({ isLoading: false }))
            _handleToggleModal('confirmationModal')
            _getData()
        } catch (e) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'Ocurrió un error al cambiar el estado del hogar',
            })
        }
    }

    return (
        <div>
            <PlaceForm
                handleClose={() => _handleToggleModal('placeFormModal')}
                open={modals.placeFormModal?.open}
                action={modals.placeFormModal?.action}
                data={modals.placeFormModal?.data}
                getData={_getData}
            />

            <ConfirmationModal
                open={modals?.confirmationModal?.open}
                title={modals?.confirmationModal?.data?.deleted_at ? 'Restaurar hogar' : 'Eliminar hogar'}
                onClose={() => _handleToggleModal('confirmationModal')}
                onConfirm={_handleTogglePlaceStatus}
            >
                <p>
                    ¿Está seguro que desea {modals.confirmationModal?.data?.deleted_at ? 'restaurar' : 'eliminar'} este
                    hogar?
                </p>
            </ConfirmationModal>

            <ModalComponent
                open={modals.qrcodeModal?.open}
                handleClose={() => _handleToggleModal('qrcodeModal')}
                title="Qrcode"
            >
                <QrCodeModal
                    {...modals.qrcodeModal?.data}
                    image={modals?.qrcodeModal?.data?.qr_code_image}
                    onClose={() => _handleToggleModal('qrcodeModal')}
                    link={`/place/${modals?.qrcodeModal?.data?.id}`}
                    url_token='/places/render/'
                    label_token='Ver Hogar'
                />
            </ModalComponent>

            <HomeHeader handleOpenModal={_handleToggleModal} updateTableData={setData} />

            <PlaceTable
                onUpdate={(data: any) => _handleToggleModal('placeFormModal', 'update', data)}
                onDelete={(data: any) => _handleToggleModal('confirmationModal', 'delete', data)}
                data={data.data}
                sortData={_sorData}
                getData={_getData}
                _handleToggleModal={_handleToggleModal}
            />

            <div className="w-full pb-10 mt-5">
                <PaginationComponent pagination={data} onRequestEnd={setData} />
            </div>
        </div>
    )
}

export default Places
