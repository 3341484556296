export const TableHeader = [
    {
        label: 'ID',
        key: 'id',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Nombre',
        key: 'name',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Hogar',
        key: 'place_name',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'QR',
        key: 'qr_code_image',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Total Usuarios',
        key: 'count_users',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Creado en',
        key: 'created_at',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Status',
        key: 'deleted_at',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
]
