import moment from 'moment'
import ModalComponent from '../../components/ModalComponent'

const LogModal = ({ open, title, handleClose, data }: any) => {
    return (
        <ModalComponent size="lg" open={open} title={`${title} # ${data?.id}`} handleClose={handleClose}>
            <div className="w-full flex flex-wrap">
                <div className="w-full md:w-[50%] text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Descripción</span>
                    <span className="ms-2">{data?.description}</span>
                </div>

                <div className="w-full md:w-[50%] text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Acción</span>
                    <span className="ms-2">{data?.action}</span>
                </div>

                <div className="w-full md:w-[50%] text-[12px] flex flex-col mb-4">
                    <span className="font-bold">IP</span>
                    <span className="ms-2">{data?.ip}</span>
                </div>

                <div className="w-full md:w-[50%] text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Metodo</span>
                    <span className="ms-2">{data?.method}</span>
                </div>

                <div className="w-full md:w-[50%] text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Level</span>
                    <span className="ms-2">{data?.level}</span>
                </div>

                <div className="w-full md:w-[50%] text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Creado al dia</span>
                    <span className="ms-2">{moment(data?.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                </div>

                <div className="w-full text-[12px] flex flex-col mb-4">
                    <span className="font-bold">Respuesta</span>
                    <span className="ms-2">
                        <pre className="">
                            {data &&
                                data?.response &&
                                typeof data.response === 'string' &&
                                JSON.stringify(JSON.parse(data?.response), undefined, 2)}
                            {data &&
                                data?.response &&
                                typeof data.response === 'object' &&
                                JSON.stringify(data?.response, undefined, 2)}
                        </pre>
                    </span>
                </div>
            </div>
        </ModalComponent>
    )
}

export default LogModal
