import { useDispatch, useSelector } from 'react-redux'
import { _pushToastMessage } from '../../helpers/messages'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import RestService from '../../services/rest.service'
import { setLoading } from '../../redux/slicers/loader'

interface IPaginationComponent {
    pagination: IPaginatedPage
    onRequestEnd: (data: any) => void
}

const PaginationComponent = ({ pagination, onRequestEnd }: IPaginationComponent) => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state: any) => state.loader)
    const _onNext = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new RestService().get({ url: pagination.next_page_url || '' })
            onRequestEnd(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            _pushToastMessage({ type: 'error', header: 'Error', text: e?.message })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _onPrevious = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new RestService().get({ url: pagination.prev_page_url || '' })
            onRequestEnd(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: e?.message })
        }
    }

    const _onLastPage = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new RestService().get({ url: pagination.last_page_url || '' })
            onRequestEnd(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: e?.message })
        }
    }

    const _onFirstPage = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new RestService().get({ url: pagination.first_page_url || '' })
            onRequestEnd(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: e?.message })
        }
    }

    return (
        <div className="w-full mb-5">
            <div className="flex justify-center">
                <div className="rounded-l-md border">
                    <button
                        className="px-3 py-1 disabled:bg-gray-200"
                        disabled={pagination.current_page === 1 || pagination.current_page === 0 || isLoading}
                        onClick={_onFirstPage}
                    >
                        <i className="zmdi zmdi-chevron-left"></i>
                        <i className="zmdi zmdi-chevron-left"></i>
                    </button>
                </div>
                <div className="border">
                    <button
                        className="px-3 py-1 disabled:bg-gray-200"
                        disabled={pagination.current_page === 1 || pagination.current_page === 0 || isLoading}
                        onClick={_onPrevious}
                    >
                        <i className="zmdi zmdi-chevron-left"></i>
                    </button>
                </div>
                <div className="px-3 py-1 border-t border-b">
                    {pagination.current_page} de {pagination.last_page}
                </div>
                <div className=" border">
                    <button
                        className="disabled:bg-gray-200 px-3 py-1"
                        disabled={pagination.current_page === pagination.last_page || isLoading}
                        onClick={_onNext}
                    >
                        <i className="zmdi zmdi-chevron-right"></i>
                    </button>
                </div>
                <div className="rounded-r-md border">
                    <button
                        className="disabled:bg-gray-200 px-3 py-1"
                        disabled={pagination.current_page === pagination.last_page || isLoading}
                        onClick={_onLastPage}
                    >
                        <i className="zmdi zmdi-chevron-right"></i>
                        <i className="zmdi zmdi-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PaginationComponent
