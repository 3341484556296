/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "rsuite";
import WhisperComponent from "../../../components/WhisperComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/slicers/loader";

const UserList = ({ data, handleClose }: { data: any, handleClose: () => void }) => {

    const [userListName, setUserListName] = useState<string>("list_of_patients_to_caregiver");
    const { loader } = useSelector((state: any) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading({ isLoading: true }))
        setTimeout(() => {
            if (data.roles?.[0]?.label?.toLowerCase() === "tutor") setUserListName("list_of_patients_to_tutor")
            if (data.roles?.[0]?.label?.toLowerCase() === "cuidador") setUserListName("list_of_patients_to_caregiver")
            dispatch(setLoading({ isLoading: false }))
        }, 500)
    }, [])

    return (
        <div className="w-full">
            <Table data={data[userListName] || []} className="w-full" locale={{ emptyMessage: "No se encontraron usuarios" }} autoHeight loading={loader.isLoading} >
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">ID</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.id}`} >
                                        <span className="size-08">{`${user.id}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Nombre</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.name}`} >
                                        <span className="size-08">{`${user.name}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Email</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.email}`} >
                                        <span className="size-08">{`${user.email}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Teléfono</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.phone_number}`} >
                                        <span className="size-08">{`${user.phone_number}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell align="center">Status</Table.HeaderCell>
                    <Table.Cell >
                        {
                            (user) => {
                                return (
                                    <WhisperComponent text={`${user.deleted_at ? "Inactivo" : "Activo"}`} >
                                        <span className="size-08">{`${user.deleted_at ? "Inactivo" : "Activo"}`}</span>
                                    </WhisperComponent>
                                )
                            }
                        }
                    </Table.Cell>
                </Table.Column>
            </Table>

            <div className="flex justify-end w-full mt-10">
                <button className=" button me-3 border-red-400" onClick={handleClose} >Cerrar</button>
            </div>
        </div>
    );
}

export default UserList;