import PngLogo from '../../assets/images/png/lectrogram_iso.png'

const HeaderTokenPage = () => {
    return (
        <header className="h-[6vh] shadow-sm border-b flex items-center px-5 py-8 sm:py-6 justify-between sm:justify-end fixed bg-white z-10 w-full">
            <div className="text-start w-full text-[24px]">
                <img src={PngLogo} alt="logo" className=" w-[50px] inline-block me-3" />
                Lectogram
            </div>
        </header>
    )
}

export default HeaderTokenPage
