/* eslint-disable react-hooks/exhaustive-deps */
import InputText from '../../components/InputText'
import ModalComponent from '../../components/ModalComponent'
import { useEffect, useState } from 'react'
import { validateYupSchema } from '../../helpers/utils'
import { _pushToastMessage } from '../../helpers/messages'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { CreatePlaceSchema } from '../../validations/place.validations'
import PlaceService from '../../services/places.service'
import { TagPicker } from 'rsuite'
import UserService from '../../services/users.service'
import ErrorValidation from '../../components/ErrorValidation'
import ConfirmationModal from '../../components/ConfirmationModal'
import CategoryService from '../../services/category.service'
import SelectWithCategoryImage from '../../components/SelectWithCategoryImage'

interface IPlacesForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    getData: () => void
}
const PlaceForm = ({ handleClose, action, open, data, getData }: IPlacesForm) => {
    const dispatch = useDispatch()
    const { loader } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: {
            name: '',
            description: '',
            logo_img: '',
            patients_id: [],
            media_id: null,
        },
        validations: {},
    })
    const [patients, setPatients] = useState<any[]>([])
    const [medias, setMedias] = useState<any[]>([])
    const [confirmationModal, setConfirmationModal] = useState<any>({
        open: false,
        data: null,
    })
    const [mainModal, setMainModal] = useState<any>({
        open: true,
    })

    useEffect(() => {
        if (!open) return
        setMainModal({ open: true })
        _handleSearchPatient('')
        _getPlaceCategoryData()
        if (action === 'update') {
            setState({
                ...state,
                validations: {},
                data: {
                    ...data,
                    patients_id: data.patients.map((p: any) => p.id),
                },
            })
            return
        }

        setState({
            ...state,
            validations: {},
            data: {
                name: '',
                description: '',
                logo_img: '',
                patients_id: [],
                media_id: null,
            },
        })
    }, [open])

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleRSuiteOnChange = (value: any, name: string) => {
        if (name === 'media_id' && value !== 'other') {
            state.data.logo_img = ''
        }

        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSearchPatient = async (value: string) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { data } = await new UserService().searchPatientsToSelect(value)
            setPatients(data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            console.log(e)
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _getPlaceCategoryData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const { data } = await new CategoryService().getCategory({ params: { search: 'buildings' } })
            setMedias([...data?.medias, { id: 'other', name: 'Otro' }])
            dispatch(setLoading({ isLoading: false }))
        } catch (e) {
            console.log(e)
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleSubmit = async () => {
        try {
            const schema = CreatePlaceSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            if (action === 'update') {
                await new PlaceService().updatePlace(state.data)
            }

            if (action === 'create') {
                await new PlaceService().createPlace(state.data)
            }

            dispatch(setLoading({ isLoading: false }))
            setConfirmationModal({ ...confirmationModal, open: false })
            handleClose()
            getData()

            const message = action === 'create' ? 'Hogar creado con exito' : 'Hogar actualizado con exito'
            _pushToastMessage({ type: 'success', header: 'Exito', text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    const _handlePreSubmit = async () => {
        try {
            const schema = CreatePlaceSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))
            if (action === 'update') {
                await new PlaceService().checkUserPlace({ place_id: data?.id, patients_id: state.data.patients_id })
            }
            _handleSubmit()
        } catch (err: any) {
            setConfirmationModal({ open: true, data: state.data })
            setMainModal({ open: false })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleCloseConfirmationModal = () => {
        setConfirmationModal({ ...confirmationModal, open: false })
        setMainModal({ open: true })
    }

    return (
        <>
            <ConfirmationModal
                open={confirmationModal?.open}
                title={'Confirmación'}
                onClose={_handleCloseConfirmationModal}
                onConfirm={_handleSubmit}
                size="lg"
            >
                <p>
                    Detectamos que esta intentando cambiar los usuarios de este hogar. Esto puede afectar a las
                    estancias asociadas a este hogar. Seguro que desea continuar?
                </p>
            </ConfirmationModal>
            <ModalComponent
                open={open && mainModal?.open}
                title={`${action === 'create' ? 'Crear hogar' : 'Actualizar hogar'}`}
                handleClose={handleClose}
            >
                <div className="w-full flex flex-wrap justify-between">
                    <div className="mb-2 w-full">
                        <InputText
                            name="name"
                            type="text"
                            label="Nombre"
                            validations={state.validations}
                            onChange={_handleOnChange}
                            value={state.data?.name}
                        />
                    </div>
                    <div className="mb-2 w-full">
                        <InputText
                            name="description"
                            type="text"
                            label="Descripción"
                            validations={state.validations}
                            onChange={_handleOnChange}
                            value={state.data?.description}
                        />
                    </div>

                    <div className="mb-2 w-full">
                        <label className="text-[12px]">Usuarios</label>
                        <TagPicker
                            data={patients}
                            labelKey="name"
                            valueKey="id"
                            placeholder="Informa los usuario"
                            locale={{
                                noResultsText: 'No se encontraron resultados',
                                searchPlaceholder: 'Buscar usuario',
                            }}
                            style={{ width: '100%' }}
                            value={state.data?.patients_id}
                            onChange={(value: any) => _handleRSuiteOnChange(value, 'patients_id')}
                        />
                        <ErrorValidation
                            validations={state.validations}
                            name={'patients_id'}
                            className="text-red-400 text-[11px] text-end"
                        />
                    </div>

                    <SelectWithCategoryImage
                        onChange={_handleRSuiteOnChange}
                        data={medias}
                        value={state.data?.media_id}
                    />

                    {state.data?.logo_img && (
                        <div className="mb-2 mt-4 ps-5 w-full">
                            <img src={state.data?.logo_img} alt="logo" className="w-[130px]" />
                        </div>
                    )}

                    <div className="flex justify-end w-full mt-10">
                        <button
                            className=" button me-3 border-red-400"
                            onClick={handleClose}
                            disabled={loader.isLoading}
                        >
                            Cerrar
                        </button>
                        <button className="button" onClick={_handlePreSubmit} disabled={loader.isLoading}>
                            Confirmar
                        </button>
                    </div>
                </div>
            </ModalComponent>
        </>
    )
}

export default PlaceForm
