import instance from './api'
import store from '../redux/store'

interface IPlace {
    id?: string
    name: string
    description: string
}

class PlaceService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async createPlace(place: IPlace) {
        try {
            const response = await instance.post('/api/places/create', place)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updatePlace(place: IPlace) {
        try {
            const response = await instance.put(`/api/places/update/${place?.id}`, place)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getPlaces() {
        this._setTokens()
        try {
            const response = await instance.get('/api/places')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getSelectPlaces() {
        this._setTokens()
        try {
            const response = await instance.get('/api/places/get/select-data')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deletePlace(id: string) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/places/delete/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async restorePlace(id: string) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/places/restore/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getSelectRooms(placeId: string) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/places/select-rooms/${placeId}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getPlace(id: any) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/places/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getTokenablePlace({ token }: { token: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/place/private/${token}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async checkUserPlace({ place_id, patients_id }: { place_id: string; patients_id: string[] }) {
        this._setTokens()
        try {
            const response = await instance.post(`/api/places/validate/patients`, { place_id, patients_id })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default PlaceService
