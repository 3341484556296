/* eslint-disable react-hooks/exhaustive-deps */
import { Uploader } from "rsuite";
import ModalComponent from "../../components/ModalComponent";
import { useEffect, useState } from "react";
import { createCsvSimple, validateYupSchema } from "../../helpers/utils";
import { MassiveLoadUserSchema } from "../../validations/user.validations";
import { _pushToastMessage } from "../../helpers/messages";

interface IMassiveLoadForm {
    open: boolean;
    handleClose: () => void;
}

const MassiveLoadForm = ({ open, handleClose }: IMassiveLoadForm) => {

    const [state, setState] = useState({
        fileList: [],
        csvData: [],
        errors: []
    })

    useEffect(() => {
        if (!open) return
        setState({
            ...state,
            fileList: [],
            csvData: [],
            errors: []
        })
    }, [open])

    const _handleFile = (e: any) => {
        let data: any = null
        if (e.length === 0) {
            setState({
                ...state,
                fileList: [],
                csvData: [],
                errors: []
            })
            return
        }

        const reader = new FileReader()

        reader.onload = async () => {
            data = reader.result
            const arrayData = data.split('\n')
            const headers = ["id", "name", "email", "role", "phone_number", "password", "confirmation_password"]
            const errors: any = []

            const csvObject: any = [];
            let delimiter = ';'
            let index = 0

            arrayData.shift()

            for (const el of arrayData) {
                index++;

                let row = el.split(delimiter)

                /**
                 * If the row length is different from the headers length, change the delimiter and split again
                 */

                if (row.length !== headers.length) {
                    delimiter = ','
                    row = el.split(delimiter)
                }

                /**
                 * If the row length is different from the headers length, it means that the csv is not well formed
                 */

                if (row.length !== headers.length) continue;

                const obj: any = {}

                let emptyFields = 0;

                headers.forEach((header: any, index: number) => {
                    if (row[index] === "") emptyFields++;
                    obj[header] = row[index]
                })

                /**
                 * If all the fields are empty, it means that the row is empty
                 */

                if (emptyFields === headers.length) continue;


                const response = await validateData(obj, index)

                csvObject.push(obj)

                if (response.hasErrors) {
                    errors.push(response)
                }
            }


            setState((prevState: any) => {
                return {
                    ...prevState,
                    fileList: [e[e.length - 1]],
                    csvData: csvObject,
                    errors
                }
            })
        }
        reader.readAsBinaryString(e[e.length - 1].blobFile)
    }

    const validateData = async (item: any, line: number) => {
        let response: any = {
            line: 0,
            hasErrors: false,
            errors: []
        }

        let validations = await validateYupSchema(MassiveLoadUserSchema, item)

        if (validations) {
            response.hasErrors = true
            response.line = line
            response.errors = Object.values(validations).flat()
        }

        return response
    }

    const downloadCsvBase = () => {
        const headers = ["id", "name", "email", "role", "phone_number", "password", "confirmation_password"]
        const csv: any = headers.join(',') + '\n';
        createCsvSimple(csv, 'carga_masiva_usuarios_lectogram.csv');
    }

    const _handleSubmit = () => {
        handleClose();
        _pushToastMessage({ type: "success", header: "Éxito", text: "Usuarios cargados correctamente" })
    }

    return (
        <ModalComponent open={open} title="Carga masiva de usuarios" handleClose={handleClose}>
            <div className="w-full">
                <div className="w-full">
                    <Uploader
                        accept=".csv"
                        listType="text"
                        fileList={state?.fileList}
                        onChange={(e: any) => _handleFile(e)}
                        autoUpload={false}
                        action=""
                    >
                        <button className="button">
                            <span className="me-1">+</span> Seleccionar archivo
                        </button>
                    </Uploader>
                </div>

                <div className="w-full">
                    {state.errors.length > 0 && (
                        <div className="w-full mt-5">
                            <div className="w-full flex flex-col">
                                <span className="bg-gray-200 border rounded-md border-gray-500 p-5 text-gray-500 mb-4 text-[13px]">Errores en el archivo. Corrige los errores, elimine el archivo de la lista y cárguelo nuevamente.</span>
                                <div className="w-full flex justify-between border-b-2">
                                    <div className="w-1/6 text-start font-bold">Línea</div>
                                    <div className="w-5/6 text-start font-bold">Errores</div>
                                </div>
                                {state.errors.map((error: any, index: number) => (
                                    <div className="w-full flex justify-between border-b-2 py-4" key={index}>
                                        <div className="w-1/6 text-start">{error.line}</div>
                                        <div className="w-5/6 text-start">
                                            {error.errors.map((error: any, index: number) => (
                                                <div key={index}>{error}</div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="w-full">
                    <div className="w-full flex justify-end mt-5">
                        <button className="button me-2" onClick={downloadCsvBase}>Descargar formato</button>
                        <button className="button" disabled={state.csvData?.length === 0 || state.errors.length > 0} onClick={_handleSubmit}>Confirmar</button>
                    </div>
                </div>
            </div>
        </ModalComponent>
    )
}

export default MassiveLoadForm;