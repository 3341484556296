/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import { Link, useParams } from 'react-router-dom'
import PlaceService from '../../services/places.service'
import LivingRoom from '../../assets/images/png/living-room.png'
import FooterTokenPage from '../../components/FooterTokenPage'
import HeaderTokenPage from '../../components/HeaderTokenPage'

const PlaceToken = () => {
    const [data, setData] = useState<any>({})

    const dispatch = useDispatch()

    const params = useParams()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new PlaceService().getTokenablePlace({ token: params.id || '' })
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'No se pudo cargar las tareas',
            })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <div className="w-full">
            <HeaderTokenPage />
            <div className="h-[90vh] overflow-y-auto flex flex-wrap gap-4 w-full sm:justify-around justify-between sm:items-center items-start pt-20 px-5 pb-5">
                {data &&
                    data.rooms &&
                    data.rooms.map((room: any, index: number) => (
                        <Link
                            to={`/rooms/render/${room.token?.token}`}
                            key={index}
                            className="border rounded w-[150px] text-[14px] shadow-sm px-5 py-3 h-[200px] overflow-hidden"
                        >
                            <div className="flex flex-col justify-between h-full">
                                {room?.qr_code_icon && <img src={room.qr_code_icon} alt="icon" />}
                                {!room?.qr_code_icon && <img src={LivingRoom} alt="icon" />}
                                {room?.name && (
                                    <div className="text-center mt-2 text-ellipsis overflow-hidden w-full">
                                        {room.name}
                                    </div>
                                )}
                            </div>
                        </Link>
                    ))}

                {!data?.rooms ||
                    (data?.rooms?.length === 0 && (
                        <div className="text-center w-full">No hay estancias disponibles</div>
                    ))}
            </div>
            <FooterTokenPage />
        </div>
    )
}

export default PlaceToken
