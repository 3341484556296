import ErrorValidation from "../ErrorValidation"

interface IInputText extends React.InputHTMLAttributes<HTMLInputElement> {
    validations: any,
    name: string,
    label: string
}

const InputText = ({ validations, name, label, ...rest }: IInputText) => {
    return (
        <>
            <span className={`${validations[name] ? 'text-red-400' : ""} text-[12px] `}>{label}</span>
            <input className={`${validations[name] ? 'input-text-red' : "input-text"}  w-full outline-none `} name={name} {...rest} />
            <ErrorValidation validations={validations} name={name} className="text-red-400 text-[11px] text-end" />
        </>
    )
}

export default InputText;