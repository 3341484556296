/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Home = () => {
    const { session } = useSelector((state: any) => state)
    const navigate = useNavigate()

    useEffect(() => {
        handleRedirects()
    }, [])

    const handleRedirects = () => {
        if (session.isLogged) {
            if (session?.profile?.roles[0]?.value === 'paciente') {
                navigate('/routines')
                return;
            }

            navigate('/users')

            return;
        }

        navigate('/signin')
    }
    return (
        <div className="flex w-full justify-center items-center h-full">
            <div>Cargando...</div>
        </div>
    )
}

export default Home
