const PrivacyPolicies = () => {
    return (
        <div className="min-h-[100vh] flex justify-center bg-gray-200 text-gray-700 p-5">
            <div className="rounded-lg shadow-md bg-white px-8 pt-10 pb-4 md:min-w-[90%] max-w-[99%]">
                <h1 className="mb-8 text-center">Políticas de privacidad</h1>
                <p>
                    Lectogram valora la privacidad de sus usuarios. Esta política describe cómo recopilamos, usamos, y
                    compartimos su información personal cuando usa nuestra aplicación.
                </p>

                <p className="my-5">
                    <b>Información que Recopilamos:</b>
                </p>

                <p>Información de cuenta: nombre, dirección de correo electrónico, y contraseña.</p>
                <p>Información de uso: historial de lectura, libros guardados, notas y resaltados.</p>

                <p className="my-5">
                    <b>Datos técnicos:</b>
                </p>

                <p>Dirección IP, tipo de dispositivo, sistema operativo y versión de la aplicación.</p>

                <p className="my-5">
                    <b>Uso de la Información:</b>
                </p>

                <p>Para proporcionar y mejorar nuestros servicios.</p>
                <p>Para personalizar su experiencia de lectura.</p>
                <p>Para comunicarnos con usted sobre actualizaciones y promociones.</p>

                <p className="my-5">
                    <b>Distribución de Información:</b>
                </p>

                <p>
                    No compartimos su información personal con terceros sin su consentimiento, excepto cuando sea
                    necesario para cumplir con la ley, proteger nuestros derechos, o llevar a cabo operaciones del
                    servicio.
                </p>

                <p className="my-5">
                    <b>Seguridad:</b>
                </p>

                <p>
                    Implementamos medidas de seguridad para proteger su información personal. Sin embargo, ningún método
                    de transmisión por internet o almacenamiento electrónico es completamente seguro.
                </p>

                <p className="my-5">
                    <b>Derechos:</b>
                </p>

                <p>
                    Puede acceder, corregir o eliminar su información personal contactándonos a
                    <a href="mailto:lectogram@udla.cl">lectogram@udla.cl</a>
                </p>
                <p className="my-5">
                    <b>Cambios a esta Política:</b>
                </p>

                <p>
                    Podemos actualizar esta política ocasionalmente. Le notificaremos sobre cualquier cambio mediante un
                    aviso en nuestra aplicación.
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicies
