/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import { Link, useParams } from 'react-router-dom'
import PngRoutine from '../../assets/images/png/routine.png'

import RoomService from '../../services/rooms.service'
import FooterTokenPage from '../../components/FooterTokenPage'
import HeaderTokenPage from '../../components/HeaderTokenPage'

const RoomToken = () => {
    const [data, setData] = useState<any>({})

    const dispatch = useDispatch()

    const params = useParams()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new RoomService().getTokenableRoom({ token: params.id || '' })
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({
                type: 'error',
                header: 'Error',
                text: 'No se pudo cargar las tareas',
            })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <div className="w-full">
            <HeaderTokenPage />
            <div className="h-[90vh] overflow-y-auto  pt-20 px-5 pb-5">
                <div className="w-full text-[14px] mb-5">
                    <Link to={`/places/render/${data?.place?.token?.token}`} className="text-blue-500">
                        <i className="zmdi zmdi-chevron-left me-2"></i>
                        Volver
                    </Link>
                </div>
                <div className="flex flex-wrap gap-4 w-full sm:justify-center justify-between items-center">
                    {data &&
                        data.routines &&
                        data.routines.map((routine: any, index: number) => (
                            <Link
                                to={`/routines/render/${routine.token?.token}`}
                                key={index}
                                className="border rounded w-[150px] text-[14px] shadow-sm px-5 py-3 h-[200px] overflow-hidden"
                            >
                                <div className="flex flex-col justify-between h-full">
                                    {routine?.qr_code_icon && (
                                        <img src={routine.qr_code_icon} className="w-[110px]" alt="icon" />
                                    )}
                                    {!routine?.qr_code_icon && (
                                        <img src={PngRoutine} alt="icon" className="w-[110px]" />
                                    )}
                                    {routine?.name && (
                                        <div className="text-center mt-2 text-ellipsis overflow-hidden w-full">
                                            {routine.name}
                                        </div>
                                    )}
                                </div>
                            </Link>
                        ))}

                    {!data?.routines ||
                        (data?.routines?.length === 0 && (
                            <div className="text-center w-full h-[42vh] flex justify-center items-center">
                                No hay rutinas disponibles
                            </div>
                        ))}
                </div>
            </div>

            <FooterTokenPage />
        </div>
    )
}

export default RoomToken
