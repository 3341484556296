export const TableHeader = [
    {
        label: 'ID',
        key: 'id',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Nombre',
        key: 'name',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Email',
        key: 'email',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Teléfono',
        key: 'phone_number',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Role',
        key: 'roles',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Cuidadores',
        key: 'caregiver',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Status',
        key: 'deleted_at',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Creado en',
        key: 'created_at',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    }
]