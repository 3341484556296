import ModalComponent from '../../components/ModalComponent'

interface IRoutineTypeModal {
    open: boolean
    handleClose: () => void
    handleSelectedOption: (option: number) => void
}
const RoutineTypeModal = ({ open, handleClose, handleSelectedOption }: IRoutineTypeModal) => {
    return (
        <ModalComponent open={open} title="" handleClose={handleClose}>
            <div className="w-full flex">
                <div className="w-full flex justify-center py-10">

                    <div className="w-1/2">
                        <div className="flex justify-center">
                            <button
                                className="flex flex-col border rounded items-center p-5 shadow"
                                onClick={() => {
                                    handleSelectedOption(1)
                                }}
                            >
                                <i className="zmdi zmdi-alarm-plus text-[32px] pb-5"></i>
                                Crear nueva rutina
                            </button>
                        </div>
                    </div>

                    <div className="w-1/2">
                        <div className="flex justify-center">
                            <button
                                className="flex flex-col border rounded items-center p-5 shadow"
                                onClick={() => {
                                    handleSelectedOption(2)
                                }}
                            >
                                <i className="zmdi zmdi-alarm-check text-[32px] pb-5"></i>
                                Crear con un modelo
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </ModalComponent>
    )
}

export default RoutineTypeModal
