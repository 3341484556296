/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import ErrorValidation from '../../components/ErrorValidation'
import InputText from '../../components/InputText'
import ModalComponent from '../../components/ModalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { validateYupSchema } from '../../helpers/utils'
import { CreateTaskSchema } from '../../validations/task.validations'
import TaskService from '../../services/task.service'
import { useParams } from 'react-router-dom'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import PreLoadedTaskService from '../../services/pre_loaded_task.service'

interface ITaskForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    setData: (data: any) => void
    isPreLoaded?: boolean
}

const TaskForm = ({ handleClose, action, open, data, setData, isPreLoaded }: ITaskForm) => {
    const [state, setState] = useState<any>({
        data: {
            name: '',
            description: '',
        },
        validations: {},
    })

    const { loader } = useSelector((state: any) => state)

    const params = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        if (open && action === 'update' && data) {
            setState({
                ...state,
                data: {
                    name: data.name,
                    description: data.description,
                },
            })
            return
        }

        setState({
            ...state,
            validations: {},
            data: {
                name: '',
                description: '',
            },
        })
    }, [open])

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const validations = await validateYupSchema(CreateTaskSchema, state.data)

            if (validations) {
                setState({
                    ...state,
                    validations: validations,
                })
                return
            }

            dispatch(setLoading({ isLoading: true }))

            if (action === 'create') {
                if (isPreLoaded) {
                    await new PreLoadedTaskService().createTask({
                        ...state.data,
                        routine_id: params.id,
                    })
                } else {
                    await new TaskService().createTask({
                        ...state.data,
                        routine_id: params.id,
                    })
                }
            }

            if (action === 'update') {
                if (isPreLoaded) {
                    await new PreLoadedTaskService().updateTask(data.id, state.data)
                } else {
                    await new TaskService().updateTask(data.id, state.data)
                }
            }

            let response: any = null
            
            if (isPreLoaded) {
                response = await new PreLoadedTaskService().getTasks({ id: params.id })
            } else {
                response = await new TaskService().getTasks({ id: params.id })
            }

            response.data.tasks = response?.data?.tasks.map((item: any) => {
                item.voice = {
                    text: item.name,
                    lang: 'es',
                    isPlaying: false,
                }
                return item
            })
            setData(response.data)
            handleClose()
            dispatch(setLoading({ isLoading: false }))
            const message = action === 'create' ? 'Tarea creada con éxito' : 'Tarea actualizada con éxito'
            _pushToastMessage({
                type: 'success',
                header: 'Éxito',
                text: message,
            })
        } catch (e: any) {
            dispatch(setLoading({ isLoading: false }))
            const message = action === 'create' ? 'No fue posible crear la tarea' : 'No fue posible actualizar la tarea'
            _pushToastMessage({ type: 'error', header: 'Error', text: message })
        }
    }

    return (
        <ModalComponent
            open={open}
            title={`${action === 'create' ? 'Crear tarea' : 'Actualizar tarea'}`}
            handleClose={handleClose}
        >
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full">
                    <InputText
                        name="name"
                        type="text"
                        label="Nombre"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.name}
                    />
                </div>

                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Descripcíon</span>
                    <textarea
                        className={`w-full border rounded p-2 ${
                            state.validations?.description ? 'border-red-300' : ''
                        }`}
                        name="description"
                        onChange={_handleOnChange}
                        value={state.data?.description}
                    ></textarea>
                    <ErrorValidation
                        validations={state.validations}
                        name="description"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default TaskForm
