import { Table, Whisper } from 'rsuite'
import WhisperComponent from '../../../components/WhisperComponent'
import { TableHeader } from './TableHeader'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useState } from 'react'
import { _actionMenuSpeaker } from './speaker'
import { _pushToastMessage } from '../../../helpers/messages'
import { WhisperMenu } from '../../../components/WhisperMenu'

interface IRoomTable {
    data: any[]
    onUpdate: (data: any) => void
    onDelete: (data: any) => void
    attachRoutine: (data: any) => void
    sortData: (data: any) => void
    getData: () => void
    _handleToggleModal: (modal: string, action: string, data: any) => void
}

const RoomTable = ({ data, onUpdate, onDelete, attachRoutine, sortData, _handleToggleModal }: IRoomTable) => {
    const { loader } = useSelector((state: any) => state)

    const [sortColumn, setSortColumn] = useState()
    const [sortType, setSortType] = useState()

    const _handleSelectMenu = (eventKey: any) => {
        switch (eventKey.option) {
            case 0:
                break
            case 1:
                onDelete(eventKey.payload?.data)
                break
            case 2:
                onUpdate(eventKey.payload?.data)
                break;
            case 3:
                attachRoutine(eventKey.payload?.data)
                break
            default:
                _pushToastMessage({ type: 'warning', header: 'Aviso', text: 'Función disponible pronto' })
        }
    }

    const _sortTableData = (sortColumn: any, sortType: any) => {
        setSortColumn(sortColumn)
        setSortType(sortType)
        sortData({ sortColumn, sortType })
    }

    const Actions = (payload: any) => {
        const { data } = payload
        const _speaker: any = _actionMenuSpeaker(data, _handleSelectMenu)
        return (
            <Whisper
                placement="auto"
                trigger="click"
                speaker={(whisper_payload: any, ref: any) => WhisperMenu(whisper_payload, ref, _speaker)}
            >
                <i className="zmdi zmdi-more cursor-pointer"></i>
            </Whisper>
        )
    }

    return (
        <div className="w-full mt-5">
            <Table
                data={data}
                autoHeight
                rowHeight={80}
                locale={{ emptyMessage: 'No hay estancias' }}
                loading={loader.isLoading}
                onSortColumn={_sortTableData}
                sortColumn={sortColumn}
                sortType={sortType}
            >
                {TableHeader &&
                    TableHeader.map((column: any, index: any) => (
                        <Table.Column
                            align={column.align}
                            flexGrow={column.flexGrow}
                            key={`table-column-${index}`}
                            minWidth={120}
                            sortable
                        >
                            <Table.HeaderCell>
                                <span className="bold text-center" style={{ textTransform: 'capitalize' }}>
                                    {column.label}
                                </span>
                            </Table.HeaderCell>

                            <Table.Cell
                                style={{ display: 'flex', alignItems: 'center', justifyContent: column.alignFlex }}
                                dataKey={column.key}
                            >
                                {(rowData) => {
                                    switch (column.key) {
                                        case 'name':
                                            return (
                                                <WhisperComponent text={`${rowData?.name}`} capitalizeOff align="center">
                                                    <span>{`${rowData?.name}`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'created_at':
                                            return (
                                                <WhisperComponent
                                                    text={`${moment(rowData.created_at).format('DD-MM-YYYY')}`}
                                                >
                                                    <span className="size-08">{`${moment(rowData.created_at).format(
                                                        'DD-MM-YYYY'
                                                    )}`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'deleted_at':
                                            return (
                                                <WhisperComponent
                                                    text={`${rowData.deleted_at ? 'Inactivo' : 'Activo'}`}
                                                >
                                                    <span className="size-08">{`${
                                                        rowData.deleted_at ? 'Inactivo' : 'Activo'
                                                    }`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'qr_code_image':
                                            return (
                                                <img
                                                    onClick={() => _handleToggleModal('qrcodeModal', 'view', rowData)}
                                                    src={rowData.qr_code_image}
                                                    alt="qrcode"
                                                    style={{ width: '30px', height: '30px' }}
                                                    className="cursor-pointer"
                                                />
                                            )
                                        case 'place_name':
                                            return (
                                                <WhisperComponent text={`${rowData.place?.name}`}>
                                                    <span className="size-08">{`${rowData.place?.name}`}</span>
                                                </WhisperComponent>
                                            )
                                        case 'count_users':
                                            return (
                                                <WhisperComponent text={`${rowData.patients?.length}`}>
                                                    <span className="size-08">{`${rowData.patients?.length}`}</span>
                                                </WhisperComponent>
                                            )
                                        default:
                                            return (
                                                <WhisperComponent text={rowData[column.key]}>
                                                    <div className="w-full">{rowData[column.key]}</div>
                                                </WhisperComponent>
                                            )
                                    }
                                }}
                            </Table.Cell>
                        </Table.Column>
                    ))}
                <Table.Column align="center" verticalAlign="middle">
                    <Table.HeaderCell>{'Acciones'}</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            return <Actions data={rowData} />
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </div>
    )
}

export default RoomTable
