import instance from "./api";
import store from "../redux/store";

class PreLoadedRoutineService {
  _setTokens() {
    const { session } = store.getState();
    const { tokens } = session;
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokens.accessToken}`;
  }

  async getRoutines() {
    this._setTokens();
    try {
      const response = await instance.get("/api/pre-loaded-routines");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getSelectFieldRoutines() {
    this._setTokens();
    try {
      const response = await instance.get("/api/pre-loaded-routines/all");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async reorderRoutines({
    source_id,
    target_id,
  }: {
    source_id: string;
    target_id: string;
  }) {
    this._setTokens();
    try {
      const response = await instance.put("/api/pre-loaded-routines/reorder", {
        source_id,
        target_id,
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async createRoutine(routine: any) {
    this._setTokens();
    try {
      const response = await instance.post("/api/pre-loaded-routines/create", routine);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateRoutine(id: number, routine: any) {
    this._setTokens();
    try {
      const response = await instance.put(
        `/api/pre-loaded-routines/update/${id}`,
        routine
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteRoutine(id: number) {
    this._setTokens();
    try {
      const response = await instance.delete(`/api/pre-loaded-routines/delete/${id}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async toggleRoutine(id: number) {
    this._setTokens();
    try {
      const response = await instance.delete(`/api/pre-loaded-routines/toggle-status/${id}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateRoutineConfigs(id: number, configs: any) {
    this._setTokens();
    try {
      const response = await instance.put(
        `/api/pre-loaded-routines/update/configs/${id}`,
        configs
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default PreLoadedRoutineService;
