/* eslint-disable react-hooks/exhaustive-deps */
import InputText from '../../components/InputText'
import ModalComponent from '../../components/ModalComponent'
import { useState } from 'react'
import { validateYupSchema } from '../../helpers/utils'
import { UpdateProfileSchema } from '../../validations/user.validations'
import { _pushToastMessage } from '../../helpers/messages'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import UserService from '../../services/users.service'
import { signin } from '../../redux/slicers/session'

interface IProfileForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
}
const ProfileForm = ({ handleClose, action, open, data }: IProfileForm) => {
    const dispatch = useDispatch()
    const { loader, session } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: {
            ...session.profile,
        },
        validations: {},
    })

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const schema = UpdateProfileSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            await new UserService().updateProfile(state.data.id, state.data)

            dispatch(
                signin({
                    ...session,
                    profile: {
                        ...session.profile,
                        name: state.data.name,
                        email: state.data.email,
                        phone_number: state.data.phone_number,
                    },
                })
            )

            dispatch(setLoading({ isLoading: false }))

            handleClose()

            const message = 'Perfil actualizado con exito'
            _pushToastMessage({ type: 'success', header: 'Exito', text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    return (
        <ModalComponent
            open={open}
            title={`${action === 'create' ? 'Crear usuario' : 'Actualizar perfil'}`}
            handleClose={handleClose}
        >
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full">
                    <InputText
                        name="name"
                        type="text"
                        label="Nombre"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.name}
                    />
                </div>
                <div className="mb-2 w-full sm:w-[48%]">
                    <InputText
                        name="email"
                        type="text"
                        label="Email"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.email}
                        disabled={action === 'update'}
                    />
                </div>
                <div className="mb-2 w-full sm:w-[48%]">
                    <InputText
                        name="phone_number"
                        type="text"
                        label="Teléfono"
                        validations={state.validations}
                        onChange={_handleOnChange}
                        value={state.data?.phone_number}
                    />
                </div>

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default ProfileForm
