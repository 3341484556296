import { isAdmin, isCaregiver, isTutor, loggedUserHasPermitionTo } from '../../helpers/reduxHelpers'

export const _actionMenuSpeaker = (data: any, onSelect: any) => {
    return {
        menu: [
            {
                label: `${data?.deleted_at ? 'Restaurar' : 'Desactivar'} rutina`,
                Icon: `zmdi zmdi-${data.deleted_at ? 'refresh-alt' : 'delete'} me-2`,
                show: loggedUserHasPermitionTo(['delete']) && (isAdmin() || isTutor() || isCaregiver()),
                eventKey: 1,
            },
            {
                label: `Editar rutina`,
                Icon: `zmdi zmdi-edit me-2`,
                show: loggedUserHasPermitionTo(['update']) && (isAdmin() || isTutor() || isCaregiver()),
                eventKey: 2,
            },
            {
                label: `Ver tareas`,
                Icon: `zmdi zmdi-assignment-o me-2`,
                show: loggedUserHasPermitionTo(['read']),
                eventKey: 3,
            },
            {
                label: `Configuraciones de voz`,
                Icon: `zmdi zmdi-brightness-5 me-2`,
                show: loggedUserHasPermitionTo(['update']) && (isAdmin() || isTutor() || isCaregiver()),
                eventKey: 4,
            },
            {
                label: `Asociar Hogar`,
                Icon: `zmdi zmdi-home me-2`,
                // show: loggedUserHasPermitionTo(['update']) && (isAdmin() || isTutor() || isCaregiver()),
                show: false,
                eventKey: 6,
            },
            {
                label: `Imprimir QR Code`,
                Icon: `zmdi zmdi-print me-2`,
                show: loggedUserHasPermitionTo(['read']),
                eventKey: 5,
            },
        ],
        _handleSelect: (eventKey: any, payload: any) =>
            onSelect({
                option: eventKey,
                payload: payload,
            }),
        data: data,
    }
}
