import instance from './api'
import store from '../redux/store'

interface IRoom {
    id?: string
    name: string
    description: string
    place_id: string
}

class RoomService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async createRoom(room: IRoom) {
        try {
            const response = await instance.post('/api/rooms/create', room)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateRoom(room: IRoom) {
        try {
            const response = await instance.put(`/api/rooms/update/${room?.id}`, room)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getRooms() {
        this._setTokens()
        try {
            const response = await instance.get('/api/rooms')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deleteRoom(id: string) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/rooms/delete/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async restoreRoom(id: string) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/rooms/restore/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async attachRoutine(data: any) {
        this._setTokens()
        try {
            const response = await instance.post(`/api/rooms/attach/routines/${data.room_id}`, data)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getRoom(id: any) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/rooms/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getTokenableRoom(data: any) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/room/private/${data.token}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default RoomService
