export const DatePickerSpanishLocale = {
  sunday: "Dom",
  monday: "Lun",
  tuesday: "Mar",
  wednesday: "Mie",
  thursday: "Jue",
  friday: "Vie",
  saturday: "Sab",
  ok: "OK",
  today: "Hoy",
  yesterday: "Ayer",
  hours: "Horas",
  minutes: "Minutos",
  seconds: "Segundos",
};
