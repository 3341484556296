/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import InputText from "../../components/InputText";
import UserService from "../../services/users.service";
import IPaginatedPage from "../../interfaces/IPaginatedPage";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slicers/loader";
import { _pushToastMessage } from "../../helpers/messages";
import PaginationComponent from "../../components/PaginationComponent";
import UsersTableTutor from "../../tables/UsersTableTutor";

interface IAssociatePatientToTutor {
    tutor: any[];
    updateTutorData: (data: any) => void;
}

const AssociatePatientToTutor = ({ tutor, updateTutorData }: IAssociatePatientToTutor) => {

    const [state, setState] = useState<any>({
        search: ""
    });

    const [users, setUsers] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: "",
        from: 0,
        last_page: 0,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: 0,
        prev_page_url: "",
        to: 0,
        total: 0,
        sortColumn: "",
        sortType: ""
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading({ isLoading: true }))
        setTimeout(() => {
            dispatch(setLoading({ isLoading: false }))
        }, 400);
    }, [])

    const _handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new UserService().searchPatient(state.search);
            setUsers(response.data);
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: "error", header: "Error", text: "Ocurrió un error al buscar los usuarios" })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleOnRequestEnd = (data: any) => {
        setUsers(data);
    }

    return (
        <div className="w-full" >
            <form className="w-full" onSubmit={_handleSubmit} autoComplete="off">
                <div className="w-full flex items-end">
                    <div className="min-w-[90%]">
                        <InputText
                            name="search"
                            label="Buscar por usuarios"
                            validations={{}}
                            placeholder="Ingresa el nombre o correo del usuario"
                            value={state.search}
                            onChange={(e: any) => setState({ ...state, search: e.target.value })}
                        />
                    </div>
                    <button type="submit" className="btn-primary w-full mt-2 mb-1">Buscar</button>
                </div>
            </form>

            <div className="w-full">
                <UsersTableTutor data={users.data} tutor={tutor} updateTutorData={updateTutorData} />
            </div>
            <div className="w-full mt-3">
                <PaginationComponent pagination={users} onRequestEnd={_handleOnRequestEnd} />
            </div>
        </div>
    );
}

export default AssociatePatientToTutor;