import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const DraggableRow = ({ children, onDrag, id, rowData, ...rest }: any) => {
    const ref = useRef(null);

    const ItemTypes = {
        COLUMN: "column",
        ROW: "row"
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.ROW,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop(item: any, monitor: any) {
            onDrag && onDrag(item.id, rowData.id);
        }
    });

    const [{ isDragging }, drag] = useDrag({
        item: { id: rowData.id },
        type: ItemTypes.ROW,
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const isActive = canDrop && isOver;

    drag(drop(ref));

    const styles: any = {
        cursor: "grab",
        opacity: isDragging ? 0.5 : 1,
        background: isActive ? "#ddd" : null,
        width: "100%",
        height: "100%",
        borderTop: isActive ? "2px solid #2589f5" : null
    };

    return (
        <div ref={ref} style={styles}>
            {children}
        </div>
    );
}

export default DraggableRow;