import store from '../redux/store'

export const isAdmin = () => {
    const state = store.getState()
    const { session }: { session: any } = state
    const admin = session?.profile?.roles?.find((role: any) => role.value === 'admin')

    if (admin) return true
    return false
}

export const isTutor = () => {
    const state = store.getState()
    const { session }: { session: any } = state
    const tutor = session?.profile?.roles?.find((role: any) => role.value === 'tutor')

    if (tutor) return true
    return false
}

export const isCaregiver = () => {
    const state = store.getState()
    const { session }: { session: any } = state
    const caregiver = session?.profile?.roles?.find((role: any) => role.value === 'cuidador')

    if (caregiver) return true
    return false
}

export const isPatient = () => {
    const state = store.getState()
    const { session }: { session: any } = state
    const patient = session?.profile?.roles?.find((role: any) => role.value === 'paciente')

    if (patient) return true
    return false
}

export const loggedUserHasPermitionTo = (permitionsToCheck: string[]) => {
    const state = store.getState()
    const { session }: { session: any } = state
    const { profile } = session
    const { permitions } = profile

    if(!permitions) return false

    const permitionsList = permitions.map((permition: any) => permition.value)

    const hasPermition = permitionsToCheck.every((permition) => permitionsList.includes(permition))

    if (hasPermition) return true
    return false
}
