/* eslint-disable react-hooks/exhaustive-deps */
// import { useState } from "react"
import { _pushToastMessage } from '../../helpers/messages'
import { _routineActionMenuSpeaker } from './headerSpeaker'
import { SelectPicker, Whisper } from 'rsuite'
import { WhisperMenu } from '../../components/WhisperMenu'
import { useEffect, useState } from 'react'
import { isAdmin, isPatient } from '../../helpers/reduxHelpers'
import RoutineService from '../../services/routine.service'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'

interface IRoutineHeader {
    handleToggleModal: (modal: string, action: string, data: any) => void
    patients: any[]
    setData: (data: any) => void
    setPatient: (data: any) => void
    patient?: any
}

const RoutineHeader = ({ handleToggleModal, patients, setData, setPatient, patient = 0 }: IRoutineHeader) => {
    const [state, setState] = useState<any>({
        search: '',
        patients: [],
        patient: 0,
    })
    const { loader } = useSelector((state: any) => state)
    const dispatch = useDispatch()

    useEffect(() => {
        if (patient.id !== state.patient) {
            setState({ ...state, patient: patient.id })
        }
    }, [patient])

    const _handleSelectMenu = (eventKey: any) => {
        switch (eventKey.option) {
            case 1:
                handleToggleModal('routineTypeModal', 'create', {})
                break
            default:
                break
        }
    }

    const _speaker: any = _routineActionMenuSpeaker({}, _handleSelectMenu)

    const _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const _handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            e.preventDefault()
            dispatch(setLoading({ isLoading: true }))
            const response = await new RoutineService().searchRoutines({ search: state.search })
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (e: any) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al buscar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleOnChangePatient = async (value: any) => {
        try {
            dispatch(setLoading({ isLoading: true }))
            setPatient({ id: value })
            let response: any = null

            if (value === 0) {
                response = await new RoutineService().getRoutines()
            } else {
                response = await new RoutineService().getRoutinesByPatient({ id: value })
            }

            setData(response.data)

            setState({ ...state, patient: value })
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo cargar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <div className="w-full flex flex-1 items-center justify-end">
            {isAdmin() && (
                <form onSubmit={_handleSubmit} className="w-full relative" autoComplete="off">
                    <input
                        role="presentation"
                        type="text"
                        className="input-text outline-none w-full"
                        placeholder="Busca por nombre o email"
                        value={state.search}
                        name="search"
                        onChange={_handleOnChange}
                        autoComplete="off"
                    />
                    <button type="submit" className="absolute right-0 text-[30px] text-gray-400 me-2">
                        <i className="zmdi zmdi-search" />
                    </button>
                </form>
            )}

            {!isAdmin() && !isPatient() && (
                <SelectPicker
                    data={patients || []}
                    valueKey="id"
                    labelKey="name"
                    placeholder="Selecciona el paciente a buscar"
                    onChange={(value) => _handleOnChangePatient(value)}
                    value={state.patient}
                    loading={loader.isLoading}
                    placement="auto"
                />
            )}

            <Whisper
                placement="auto"
                trigger="click"
                speaker={(whisper_payload: any, ref: any) => WhisperMenu(whisper_payload, ref, _speaker)}
            >
                <button className="button ms-5">
                    <i className="zmdi zmdi-plus-circle-o"></i>
                </button>
            </Whisper>
        </div>
    )
}

export default RoutineHeader
