/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { TagPicker } from 'rsuite'
import UserService from '../../services/users.service'
import { _pushToastMessage } from '../../helpers/messages'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { signin } from '../../redux/slicers/session'
interface IPermitions {
    data: any
    open: boolean
    handleClose: () => void
    setData: (data: any) => void
}

const Permitions = ({ data, open, handleClose, setData }: IPermitions) => {
    const PERMITIONS = [
        {
            label: 'Ver',
            value: 'read',
        },
        {
            label: 'Editar',
            value: 'update',
        },
        {
            label: 'Crear',
            value: 'create',
        },
        {
            label: 'Eliminar',
            value: 'delete',
        },
    ]

    const [state, setState] = useState<any>({})

    const dispatch = useDispatch()
    const { isLoading } = useSelector((state: any) => state.loader)
    const { session } = useSelector((state: any) => state)

    useEffect(() => {
        if (open && data) {
            data.permitions_value = data.permitions.map((permition: any) => permition.value)
            setState({ ...data })
        }
    }, [open])

    const _handleOnUpdate = (value: any) => {
        setState({ ...state, permitions_value: value })
    }

    const _handleSubmit = async (e: any) => {
        e.preventDefault()
        try {
            dispatch(setLoading({ isLoading: true }))
            const permitionsResponse = await new UserService().syncPermitions({
                user_id: data.id,
                permitions: state.permitions_value,
            })

            const { profile } = session

            if (profile.id === data.id) {
                dispatch(
                    signin({
                        ...session,
                        profile: {
                            ...profile,
                            permitions: permitionsResponse.data?.user?.permitions,
                        },
                    })
                )
            }

            const userResponse = await new UserService().getUsers()

            setData(userResponse.data?.data)

            _pushToastMessage({ type: 'success', header: 'Éxito', text: 'Permisos actualizados' })

            handleClose()

            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'Ocurrió un error al actualizar los permisos' })
        }
    }

    return (
        <form className="w-full" onSubmit={_handleSubmit}>
            <div className="w-full">
                <span className="text-[12px]">Selecciona los permisos para el usuario {data?.name}</span>
                <TagPicker
                    data={PERMITIONS}
                    className="w-full mt-3"
                    value={state.permitions_value || []}
                    onChange={_handleOnUpdate}
                />
            </div>

            <div className="w-full mt-10 text-end">
                <button type="button" onClick={handleClose} className="button me-3" disabled={isLoading}>
                    Cerrar
                </button>

                <button type="submit" className="button" disabled={isLoading}>
                    Guardar
                </button>
            </div>
        </form>
    )
}

export default Permitions
