import { isAdmin, isTutor, loggedUserHasPermitionTo } from "../../helpers/reduxHelpers";

export const _actionMenuSpeaker = (data: any, onSelect: any) => {
  return {
    menu: [
      {
        label: "Crear estancia",
        Icon: "zmdi zmdi-plus-circle-o me-2",
        show: loggedUserHasPermitionTo(["create"]) && (isAdmin() || isTutor()),
        eventKey: 1,
      },
    ],
    _handleSelect: (eventKey: any, payload: any) =>
      onSelect({
        option: eventKey,
        payload: payload,
      }),
  };
};
