/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import { _pushToastMessage } from '../../helpers/messages'
import RoutineHeader from './RoutineHeader'
import PaginationComponent from '../../components/PaginationComponent'
import RoutineConfigForm from '../../forms/Routines/RoutineConfigForm'
import PreLoadedRoutineService from '../../services/pre_loaded_routine.service'
import PreLoadedRoutineTable from '../../tables/PreLoadedRoutinesTable'
import RoutinePreLoadedForm from '../../forms/Routines/RoutinePreLoadedForm'

const RoutinesPreLoaded = () => {
    const [data, setData] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    const [modals, setModals] = useState<any>({
        preLoadedRoutineFormModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
        routineConfigFormModal: {
            open: false,
            action: '',
            title: '',
            data: null,
        },
    })

    const dispatch = useDispatch()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new PreLoadedRoutineService().getRoutines()
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo cargar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    const _handleToggleModal = (modal: string, action: string = 'create', data: any = null) => {
        setModals({
            ...modals,
            [modal]: {
                ...modals[modal],
                data: data ?? modals[modal].data,
                action: action,
                open: !modals[modal]?.open,
            },
        })
    }

    return (
        <div className="w-full">
            <RoutinePreLoadedForm
                open={modals.preLoadedRoutineFormModal.open}
                handleClose={() => _handleToggleModal('preLoadedRoutineFormModal')}
                action={modals.preLoadedRoutineFormModal.action}
                getData={_getData}
                data={modals.preLoadedRoutineFormModal.data}
            />

            <RoutineConfigForm
                open={modals.routineConfigFormModal.open}
                title="Configuración de voz"
                data={modals.routineConfigFormModal.data}
                handleClose={() => _handleToggleModal('routineConfigFormModal')}
                setData={setData}
            />

            <div className="w-full">
                <RoutineHeader handleToggleModal={_handleToggleModal} />
            </div>

            <div className="w-full">
                <PreLoadedRoutineTable
                    data={data}
                    setData={setData}
                    onEditRoutine={(data: any) => _handleToggleModal('preLoadedRoutineFormModal', 'update', data)}
                    onEditRoutineConfig={(data: any) => _handleToggleModal('routineConfigFormModal', 'update', data)}
                />
            </div>

            <div className="w-full mt-5">
                <PaginationComponent pagination={data} onRequestEnd={setData} />
            </div>
        </div>
    )
}

export default RoutinesPreLoaded
