import instance from './api'
import store from '../redux/store'

class CategoryService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getCategory({ params }: { params?: any }) {
        try {
            if (params) {
                const response = await instance.get('/api/categories', { params })
                return response
            }

            const response = await instance.get('/api/categories')
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default CategoryService
