import { isAdmin, loggedUserHasPermitionTo } from '../../helpers/reduxHelpers'

export const _routineActionMenuSpeaker = (data: any, onSelect: any) => {
    return {
        menu: [
            {
                label: 'Crear Rutina',
                Icon: 'zmdi zmdi-plus-circle-o me-2',
                show: !isAdmin() && loggedUserHasPermitionTo(['create']),
                eventKey: 1,
            },
            {
                label: 'Filtros',
                Icon: 'zmdi zmdi-filter-list me-2',
                show: false,
                eventKey: 2,
            },
        ],
        _handleSelect: (eventKey: any, payload: any) =>
            onSelect({
                option: eventKey,
                payload: payload,
            }),
        data: data,
    }
}
