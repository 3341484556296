import { isAdmin, isTutor, loggedUserHasPermitionTo } from "../../../helpers/reduxHelpers";
import { thisUserHasThatRole } from "../../../helpers/utils";

export const _actionMenuSpeaker = (data: any, onSelect: any) => {
  return {
    menu: [
      {
        label: `${data?.deleted_at ? "Restaurar" : "Desactivar"} usuario`,
        Icon: `zmdi zmdi-${data.deleted_at ? "refresh-alt" : "delete"} me-2`,
        show: loggedUserHasPermitionTo(["delete"]) && (isAdmin() || isTutor()),
        eventKey: 1,
      },
      {
        label: "Editar usuario",
        Icon: "zmdi zmdi-edit me-2",
        show: loggedUserHasPermitionTo(["update"]) && (isAdmin() || isTutor()),
        eventKey: 2,
      },
      {
        label: "Cambiar contraseña",
        Icon: "zmdi zmdi-lock me-2",
        show: loggedUserHasPermitionTo(["update"]) && (isAdmin() || isTutor()),
        eventKey: 3,
      },
      {
        label: "Asignar cuidador",
        Icon: "zmdi zmdi-assignment-account me-2",
        show: loggedUserHasPermitionTo(["update"]) && (isAdmin() || isTutor()) && thisUserHasThatRole(data, "paciente"),
        eventKey: 4,
      },
      {
        label: "Asignar usuario",
        Icon: "zmdi zmdi-assignment-account me-2",
        show: loggedUserHasPermitionTo(["update"]) && isAdmin() && thisUserHasThatRole(data, "tutor"),
        eventKey: 6,
      },
      {
        label: "Ver usuarios asignados",
        Icon: "zmdi zmdi-assignment-account me-2",
        show: loggedUserHasPermitionTo(["read"]) && (isAdmin() || isTutor()) && (thisUserHasThatRole(data, "cuidador") || thisUserHasThatRole(data, "tutor")),
        eventKey: 5,
      },
      {
        label: "Permisos",
        Icon: "zmdi zmdi-assignment-account me-2",
        show: loggedUserHasPermitionTo(["update"]) && (isAdmin() || isTutor()),
        eventKey: 7,
      },
    ],
    _handleSelect: (eventKey: any, payload: any) =>
      onSelect({
        option: eventKey,
        payload: payload,
      }),
    data: data,
  };
};
