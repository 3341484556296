/* eslint-disable react-hooks/exhaustive-deps */
import ModalComponent from '../../components/ModalComponent'
import { useEffect, useState } from 'react'
import { validateYupSchema } from '../../helpers/utils'
import { _pushToastMessage } from '../../helpers/messages'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../redux/slicers/loader'
import ErrorValidation from '../../components/ErrorValidation'
import { AttachRoutineSchema } from '../../validations/room.validations'
import UserService from '../../services/users.service'
import { TagPicker } from 'rsuite'
import RoomService from '../../services/rooms.service'

interface IRoomRoutineForm {
    open: boolean
    action: string
    data?: any
    handleClose: () => void
    getData: () => void
}
const RoomRoutineForm = ({ handleClose, action, open, data, getData }: IRoomRoutineForm) => {
    const dispatch = useDispatch()
    const { loader } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: {
            patients_id: [],
            routines_id: [],
        },
        validations: {},
    })

    const [routines, setRoutines] = useState<any[]>([])

    const [patients, setPatients] = useState<any[]>([])

    useEffect(() => {
        if (!open) return

        _getUsers()

        if (action === 'update') {
            const patients_id = data?.patients.map((patient: any) => patient.id)
            const routines_id = data?.routines.map((routine: any) => routine.id)

            setState({
                ...state,
                validations: {},
                data: {
                    ...state.data,
                    patients_id,
                    routines_id,
                    ...data,
                },
            })
            return
        }

        setState({
            ...state,
            validations: {},
            data: {
                patients_id: [],
                routines_id: [],
            },
        })
    }, [open])

    const _getUsers = async () => {
        try {
            const { data: patientsList } = await new UserService().getPatients()

            const selectedPatientsId = data?.patients?.map((patient: any) => patient.id)

            const selectedPatients = patientsList.filter((patient: any) => selectedPatientsId.includes(patient.id))

            const routines = selectedPatients
                .map((patient: any) => {
                    return patient.patient_routines.map((routine: any) => {
                        routine.name = `${routine.name} - ${patient.name}`
                        return routine
                    })
                })
                .flat()

            setRoutines(routines)
            setPatients(patientsList)
        } catch (err: any) {
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    const _handleRSuiteOnChange = (value: any, name: string) => {
        if (name === 'patients_id') {
            const selectedPatients = patients.filter((patient) => value.includes(patient.id))
            const routines = selectedPatients
                .map((patient) => {
                    return patient.patient_routines.map((routine: any) => {
                        if (routine.name.includes(patient.name)) return routine
                        routine.name = `${routine.name} - ${patient.name}`
                        return routine
                    })
                })
                .flat()

            const selectedRoutines = state.data.routines_id.filter((routine: any) =>
                routines.map((r) => r.id).includes(routine)
            )
            setState({
                ...state,
                data: {
                    ...state.data,
                    [name]: value,
                    routines_id: selectedRoutines,
                },
            })
            setRoutines(routines)
            return
        }

        setState({
            ...state,
            data: {
                ...state.data,
                [name]: value,
            },
        })
    }

    const _handleSubmit = async () => {
        try {
            const schema = AttachRoutineSchema
            const validations = await validateYupSchema(schema, state.data)
            setState({
                ...state,
                validations,
            })
            if (validations) return

            dispatch(setLoading({ isLoading: true }))

            const payload: any = {
                room_id: data.id,
                patients_id: state.data.patients_id,
                routines_id: state.data.routines_id,
            }

            await new RoomService().attachRoutine(payload)

            dispatch(setLoading({ isLoading: false }))

            handleClose()
            getData()

            const message = action === 'create' ? 'Estancia creada con exito' : 'Estancia actualizada con exito'
            _pushToastMessage({ type: 'success', header: 'Exito', text: message })
        } catch (err: any) {
            dispatch(setLoading({ isLoading: false }))
            _pushToastMessage({ type: 'error', header: 'Error', text: err.message })
        }
    }

    return (
        <ModalComponent
            open={open}
            title={`Agregar Rutinas a la estancia`}
            handleClose={handleClose}
        >
            <div className="w-full flex flex-wrap justify-between">
                <div className="mb-2 w-full">
                    <span className={`text-[12px] `}>Rutinas</span>
                    <TagPicker
                        data={routines}
                        onChange={(data) => _handleRSuiteOnChange(data, 'routines_id')}
                        className="w-full"
                        placeholder="Selecciona las rutinas"
                        searchable
                        value={state.data?.routines_id}
                        labelKey="name"
                        valueKey="id"
                        locale={{
                            emptyMessage: 'No hay resultados',
                            searchPlaceholder: 'Buscar',
                            noResultsText: 'No hay resultados',
                        }}
                    />
                    <ErrorValidation
                        validations={state.validations}
                        name="routines_id"
                        className="text-red-400 text-[11px] text-end"
                    />
                </div>

                <div className="flex justify-end w-full mt-10">
                    <button className=" button me-3 border-red-400" onClick={handleClose} disabled={loader.isLoading}>
                        Cerrar
                    </button>
                    <button className="button" onClick={_handleSubmit} disabled={loader.isLoading}>
                        Confirmar
                    </button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default RoomRoutineForm
