import { isAdmin, loggedUserHasPermitionTo } from "../../helpers/reduxHelpers";

export const _actionMenuSpeaker = (data: any, onSelect: any) => {
  return {
    menu: [
      {
        label: `${data?.deleted_at ? "Restaurar" : "Desactivar"} rutina`,
        Icon: `zmdi zmdi-${data.deleted_at ? "refresh-alt" : "delete"} me-2`,
        show: loggedUserHasPermitionTo(["delete"]) && (isAdmin()),
        eventKey: 1,
      },
      {
        label: `Editar rutina`,
        Icon: `zmdi zmdi-edit me-2`,
        show: loggedUserHasPermitionTo(["update"]) && (isAdmin()),
        eventKey: 2,
      },
      {
        label: `Ver tareas`,
        Icon: `zmdi zmdi-assignment-o me-2`,
        show: loggedUserHasPermitionTo(["read"]),
        eventKey: 3,
      }
    ],
    _handleSelect: (eventKey: any, payload: any) =>
      onSelect({
        option: eventKey,
        payload: payload,
      }),
    data: data,
  };
};