/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import IPaginatedPage from '../../interfaces/IPaginatedPage'
import { setLoading } from '../../redux/slicers/loader'
import { useDispatch } from 'react-redux'
import { _pushToastMessage } from '../../helpers/messages'
import LogService from '../../services/logs.service'
import LogsTable from '../../tables/LogsTable'
import PaginationComponent from '../../components/PaginationComponent'

const Logs = () => {
    const [data, setData] = useState<IPaginatedPage>({
        current_page: 0,
        data: [],
        first_page_url: '',
        from: 0,
        last_page: 0,
        last_page_url: '',
        links: [],
        next_page_url: '',
        path: '',
        per_page: 0,
        prev_page_url: '',
        to: 0,
        total: 0,
        sortColumn: '',
        sortType: '',
    })

    const dispatch = useDispatch()

    useEffect(() => {
        _getData()
    }, [])

    const _getData = async () => {
        try {
            dispatch(setLoading({ isLoading: true }))
            const response = await new LogService().getLogs()
            setData(response.data)
            dispatch(setLoading({ isLoading: false }))
        } catch (error) {
            _pushToastMessage({ type: 'error', header: 'Error', text: 'No se pudo cargar las rutinas' })
            dispatch(setLoading({ isLoading: false }))
        }
    }

    return (
        <div className="w-full">
            <div className="w-full mb-5">
                <LogsTable data={data.data} />
            </div>

            <div className="w-full">
                <PaginationComponent pagination={data} onRequestEnd={setData} />
            </div>
        </div>
    )
}

export default Logs
