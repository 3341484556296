/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../../router/routes'

const MobileMenu = () => {
    const { session } = useSelector((state: any) => state)
    const navigate = useNavigate()

    const [menuState, setMenuState] = useState<any>({
        open: false,
        menu: [],
        style: {
            transform: 'translateX(-100%)',
        },
    })

    useEffect(() => {
        if (!session.isLogged) {
            navigate('/signin')
            return
        }

        const menu = routes?.filter(
            (route: any) =>
                route.menu &&
                (route.permissions.includes(session?.profile?.roles[0]?.value) || route.permissions.includes('all'))
        )
        setMenuState({
            ...menuState,
            menu,
        })
    }, [session])

    const _toggleMenu = () => {
        setMenuState({
            ...menuState,
            open: !menuState.open,
            style: {
                transform: menuState.open ? 'translateX(-100%)' : 'translateX(0)',
            },
        })
    }

    return (
        <div>
            <div className="fixed w-full h-full start-0 top-0 p-0 transition-transform" style={menuState.style}>
                <div className="bg-black w-full h-full opacity-40 absolute z-10" />
                <div className="w-[80%] h-full absolute bg-white z-20">
                    <div className="flex justify-between items-center p-5">
                        <div className="text-[24px]">Lectogram</div>
                        <button onClick={_toggleMenu}>
                            <i className="zmdi zmdi-close cursor-pointer text-xl" />
                        </button>
                    </div>

                    <ul className="mt-10 px-4">
                        {menuState?.menu?.map((item: any, index: number) => (
                            <Link to={item.menu?.url} key={index} className="text-gray-600 hover:text-gray-400">
                                <li
                                    className={`py-3 px-2 ${
                                        item.path === window.location.pathname ? 'bg-gray-100 rounded shadow' : ''
                                    }`}
                                    key={index}
                                >
                                    <i className={item?.menu?.icon} />
                                    {item?.menu?.name}
                                </li>
                            </Link>
                        ))}
                        <Link to="/signout" className="text-gray-600 hover:text-gray-400">
                            <li className={`py-3 px-2`}>
                                <i className="zmdi zmdi-sign-in me-2 rotate-180" />
                                Cerrar Sesion
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>

            <div className="flex items-center sm:hidden">
                <button onClick={_toggleMenu}>
                    <i className="zmdi zmdi-menu cursor-pointer text-2xl" />
                </button>
            </div>
        </div>
    )
}

export default MobileMenu
