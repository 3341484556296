import { Notification } from "rsuite";

export interface IToastMessage {
  type: "success" | "error" | "info" | "warning";
  header: string;
  text: string;
}

const ToastMessage = ({ type, header, text }: IToastMessage) => {

  const message = (
    <Notification
      type={type}
      header={header}
      closable
      style={{ color: "#000" }}
    >
      {text}
    </Notification>
  );

  return message;
};

export default ToastMessage;
