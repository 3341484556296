import instance from './api'
import store from '../redux/store'

class LogService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getLogs() {
        this._setTokens()
        try {
            const response = await instance.get(`/api/logs`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default LogService
