/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const CreateUserSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
      "Este campo contiene caracteres inválidos"
    )
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required("Este campo es requerido"),
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("Este campo es requerido"),
  phone_number: yup
    .string()
    .min(10, "Este campo debe tener 10 caracteres")
    .max(10, "Este campo debe tener 10 caracteres")
    .required("Este campo es requerido"),
  role: yup.string().required("Este campo es requerido"),
  password: yup
    .string()
    .required("Este campo es requerido")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Este campo es requerido"),
});

export type CreateUserType = yup.InferType<typeof CreateUserSchema>;

export const UpdateUserSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
      "Este campo contiene caracteres inválidos"
    )
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required("Este campo es requerido"),
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("Este campo es requerido"),
  phone_number: yup
    .string()
    .min(10, "Este campo debe tener 10 caracteres")
    .max(10, "Este campo debe tener 10 caracteres")
    .required("Este campo es requerido"),
  role: yup.string().required("Este campo es requerido"),
});

export type UpdateUserType = yup.InferType<typeof UpdateUserSchema>;

export const PasswordUserSchema = yup.object().shape({
  password: yup
    .string()
    .required("Este campo es requerido")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Este campo es requerido"),
});

export type PasswordUserType = yup.InferType<typeof PasswordUserSchema>;

export const InvitationUserSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("Este campo es requerido"),
  role_id: yup.string().required("Este campo es requerido"),
  name: yup.string().required("Este campo es requerido"),
});

export type InvitationUserType = yup.InferType<typeof InvitationUserSchema>;

export const MassiveLoadUserSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
      "El campo nombre contiene caracteres inválidos"
    )
    .max(255, "El campo nombre acepta un máximo de 255 caracteres")
    .required("El campo nombre es requerido"),
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("El campo email es requerido"),
  phone_number: yup
    .string()
    .min(10, "El campo teléfono debe tener 10 caracteres")
    .max(10, "El campo teléfono debe tener 10 caracteres")
    .required("Este campo es requerido"),
  role: yup.string().required("El campo role es requerido"),
});

export type MassiveLoadUserType = yup.InferType<typeof MassiveLoadUserSchema>;

export const UpdateProfileSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
      "Este campo contiene caracteres inválidos"
    )
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required("Este campo es requerido"),
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("Este campo es requerido"),
  phone_number: yup
    .string()
    .min(10, "Este campo debe tener 10 caracteres")
    .max(10, "Este campo debe tener 10 caracteres")
    .required("Este campo es requerido"),
});

export type UpdateProfileType = yup.InferType<typeof UpdateProfileSchema>;

export const CreateInvitedUserSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
      "Este campo contiene caracteres inválidos"
    )
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required("Este campo es requerido"),
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("Este campo es requerido"),
  phone_number: yup
    .string()
    .min(10, "Este campo debe tener 10 caracteres")
    .max(10, "Este campo debe tener 10 caracteres")
    .required("Este campo es requerido"),
  password: yup
    .string()
    .required("Este campo es requerido")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Este campo es requerido"),
});

export type CreateInvitedUserType = yup.InferType<typeof CreateInvitedUserSchema>;

export const ValidateEmail = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("Este campo es requerido"),
});

export type ValidateEmailType = yup.InferType<typeof ValidateEmail>;

export const ValidatePassword = yup.object().shape({
  password: yup
    .string()
    .required("Este campo es requerido")
    .min(8, "La contraseña debe tener al menos 8 caracteres"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
    .required("Este campo es requerido"),
});

export type ValidatePasswordType = yup.InferType<typeof ValidatePassword>;

export const ValidateSignInSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/,
      "Formato de email no es válido"
    )
    .required("Este campo es requerido"),
  password: yup
    .string()
    .required("Este campo es requerido")
});

export type ValidateSignInType = yup.InferType<typeof ValidateSignInSchema>;