import instance from './api'
import store from '../redux/store'

class TaskService {
    _setTokens() {
        const { session } = store.getState()
        const { tokens } = session
        instance.defaults.headers.common['Authorization'] = `Bearer ${tokens.accessToken}`
    }

    async getTasks({ id }: { id: string | undefined }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/tasks/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async reorderTasks({ source_id, target_id }: { source_id: string; target_id: string }) {
        this._setTokens()
        try {
            const response = await instance.put('/api/tasks/reorder', {
                source_id,
                target_id,
            })
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createTask(task: any) {
        this._setTokens()
        try {
            const response = await instance.post('/api/tasks/create', task)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateTask(id: number, task: any) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/tasks/update/${id}`, task)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async deleteTask(id: number) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/tasks/delete/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async toggleTask(id: number) {
        this._setTokens()
        try {
            const response = await instance.delete(`/api/tasks/toggle-status/${id}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getPrivateTasks({ token }: { token: string }) {
        this._setTokens()
        try {
            const response = await instance.get(`/api/tasks/private/${token}`)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateTasksConfigs(id: number, configs: any) {
        this._setTokens()
        try {
            const response = await instance.put(`/api/tasks/update/configs/${id}`, configs)
            return response
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export default TaskService
