// import { useState } from "react"
import { _pushToastMessage } from "../../helpers/messages"
import { _taskActionMenuSpeaker } from "./headerSpeaker"
import { Whisper } from "rsuite"
import { WhisperMenu } from "../../components/WhisperMenu"

interface ITaskHeader {
    handleToggleModal: (modal: string, action: string, data: any) => void
}

const TaskHeader = ({ handleToggleModal }: ITaskHeader) => {

    const _handleSelectMenu = (eventKey: any) => {
        switch (eventKey.option) {
            case 1:
                handleToggleModal("taskFormModal", "create", {});
                break;
            default:
                _pushToastMessage({ type: "warning", header: "Aviso", text: "Función disponible pronto" })
        }
    }

    const _speaker: any = _taskActionMenuSpeaker({}, _handleSelectMenu);

    return (
        <div className="w-full flex justify-end">
            <Whisper
                placement="auto"
                trigger="click"
                speaker={(whisper_payload: any, ref: any) => WhisperMenu(whisper_payload, ref, _speaker)}
            >
                <button className="button"><i className="zmdi zmdi-plus-circle-o"></i></button>
            </Whisper>
        </div>
    )
}

export default TaskHeader;
