// import PngInstagram from '../../assets/images/png/instagram.png'
// import PngFacebook from '../../assets/images/png/facebook-logo.png'
import PngLogo from '../../assets/images/png/lectrogram_iso.png'

const FooterTokenPage = () => {
    return (
        <footer className="h-[6vh] shadow-sm border-t flex items-center px-5 py-8 sm:py-6 justify-center sm:justify-end fixed bg-white z-10 w-full bottom-0">
            <img src={PngLogo} alt="logo" className=" w-[40px] inline-block me-3" />
        </footer>
    )
}

export default FooterTokenPage
