import instance from "./api";
import store from "../redux/store";


class RolesService {
  _setTokens() {
    const { session } = store.getState();
    const { tokens } = session;
    instance.defaults.headers.common["Authorization"] = `Bearer ${tokens.accessToken}`;
  }
  
  async getRoles() {
    this._setTokens();
    try {
      const response = await instance.get("/api/roles");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default RolesService;
