import { useSelector } from "react-redux";
import { Modal } from "rsuite";

interface ConfirmationModalProps {
    open: boolean
    title: string
    children: any
    onClose: () => void
    onConfirm: () => void
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

const ConfirmationModal = ({ open, title, children, onClose, onConfirm, size = 'md' }: ConfirmationModalProps) => {

    const { loader } = useSelector((state: any) => state);
    
    return (
        <Modal open={open} onClose={onClose} size={size}>
            <Modal.Header>
                <Modal.Title> {title} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer >
                <button className="button me-2" onClick={onClose} disabled={loader.isLoading}>Cerrar</button>
                <button className="button" onClick={onConfirm} disabled={loader.isLoading}>Confirmar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal;