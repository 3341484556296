import { isAdmin, isTutor, loggedUserHasPermitionTo } from '../../../helpers/reduxHelpers'

export const _actionMenuSpeaker = (data: any, onSelect: any) => {
    return {
        menu: [
            {
                label: 'Editar hogar',
                Icon: 'zmdi zmdi-edit me-2',
                show: loggedUserHasPermitionTo(['update']) && (isAdmin() || isTutor()),
                eventKey: 2,
            },
            {
                label: `${data?.deleted_at ? 'Restaurar' : 'Eliminar'} hogar`,
                Icon: `zmdi zmdi-${data.deleted_at ? 'refresh-alt' : 'delete'} me-2`,
                show: loggedUserHasPermitionTo(['delete']) && (isAdmin() || isTutor()),
                eventKey: 1,
            },
        ],
        _handleSelect: (eventKey: any, payload: any) =>
            onSelect({
                option: eventKey,
                payload: payload,
            }),
        data: data,
    }
}
