import { Fragment } from 'react'
import { Dropdown, Popover } from 'rsuite'

export const WhisperMenu = ({ className, left, top, onClose }: any, ref: any, speaker: any) => {
    const { _handleSelect, menu } = speaker

    

    const _handleOnSelect = (eventKey: any) => {
        onClose()
        _handleSelect(eventKey, speaker)
    }

    return (
        <Popover ref={ref} className={className} style={{ left, top, minWidth: '250px' }} full>
            <Dropdown.Menu onSelect={_handleOnSelect}>
                {menu &&
                    menu.map((item: any, index: any) => (
                        <Fragment key={`${item.label}-${index}`}>
                            {item.show && (
                                <Dropdown.Item eventKey={item.eventKey !== undefined ? item.eventKey : index}>
                                    <span className="bold-300 d-flex align-items-center">
                                        <i className={item.Icon} />
                                        {item.label}
                                    </span>
                                </Dropdown.Item>
                            )}
                        </Fragment>
                    ))}

                {menu && menu.filter((item:any) => item.show).length === 0 && (
                    <Fragment>
                        <Dropdown.Item eventKey={0}>
                            <span className="bold-300 d-flex align-items-center">
                                <i className="zmdi zmdi-close me-3" />
                                No hay opciones disponibles para tu usuario
                            </span>
                        </Dropdown.Item>
                    </Fragment>
                )}
            </Dropdown.Menu>
        </Popover>
    )
}
