/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { signout } from "../../redux/slicers/session";
import { useNavigate } from "react-router-dom";
import UserService from "../../services/users.service";

const SignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    _logout();
  }, []);

  const _logout = async () => {
    try {
      await new UserService().signOut();
      dispatch(
        signout({
          isLogged: false,
          profile: null,
          tokens: null,
        })
      );
      navigate("/signin");
    } catch (error) {
      console.error(error);
      dispatch(
        signout({
          isLogged: false,
          profile: null,
          tokens: null,
        })
      );
      navigate("/signin");
    }
  };
  return null;
};

export default SignOut;
