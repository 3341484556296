/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const CreateRoomSchema = yup.object().shape({
    name: yup
        .string()
        .matches(
            /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
            'Este campo contiene caracteres inválidos'
        )
        .max(255, 'Este campo acepta un máximo de 255 caracteres')
        .required('Este campo es requerido'),
    description: yup.string().required('Este campo es requerido'),
    place_id: yup.string().required('Este campo es requerido'),
    patients_id: yup.array().min(1, 'Este campo es requerido').required('Este campo es requerido'),
})

export type CreateRoomType = yup.InferType<typeof CreateRoomSchema>

export const AttachRoutineSchema = yup.object().shape({
    patients_id: yup.array().min(1, 'Este campo es requerido').required('Este campo es requerido'),
    routines_id: yup.array().min(1, 'Este campo es requerido').required('Este campo es requerido'),
})

export type AttachRoutineType = yup.InferType<typeof AttachRoutineSchema>

export const AttachRoomSchema = yup.object().shape({
    place_id: yup.string().nullable().required('Este campo es requerido'),
    rooms_id: yup.array().min(1, 'Este campo es requerido').required('Este campo es requerido'),
})

export type AttachRoomType = yup.InferType<typeof AttachRoomSchema>
