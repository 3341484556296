export const validateYupSchema = (schema: any, inputs: any, callback: any = null) => {
    return schema
        .validate(inputs, { abortEarly: false })
        .then(() => {
            if (callback) callback()
            return false
        })
        .catch((error: any) => {
            const validationErrors: any = {}
            if (error && error.inner) {
                error.inner.forEach((errorList: any) => {
                    validationErrors[errorList.path] = errorList.errors
                })
            } 
            return validationErrors
        })
}

export const createCsvSimple = (data: any, name: any = null) => {
    try {
        let csvContent = 'data:text/csv;charset=utf-8,'
        const universalBOM: any = '\uFEFF'
        csvContent += universalBOM + data
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', name || 'carga_massiva_usuarios.csv')
        document.body.appendChild(link)
        link.click()
    } catch (e: any) {
        return false
    }
}

export const sortData = (data: any, sortColumn: any, sortType: any) => {
    if (!sortColumn || !sortType) return data

    const sortedData = data.sort((a: any, b: any) => {
        if (Array.isArray(a[sortColumn])) {
            if (sortType === 'asc') {
                return a[sortColumn][0]?.label > b[sortColumn][0]?.label ? 1 : -1
            } else {
                return a[sortColumn][0]?.label < b[sortColumn][0]?.label ? 1 : -1
            }
        } else {
            if (sortType === 'asc') {
                return a[sortColumn] > b[sortColumn] ? 1 : -1
            } else {
                return a[sortColumn] < b[sortColumn] ? 1 : -1
            }
        }
    })

    return sortedData
}

export const orderData = (data: any, sourceId: any, targetId: any) => {
    const sourceIndex = data.findIndex((item: any) => item.id === sourceId)
    const targetIndex = data.findIndex((item: any) => item.id === targetId)
    const source = data[sourceIndex]
    const target = data[targetIndex]
    data[sourceIndex] = target
    data[targetIndex] = source
    return data
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const thisUserHasThatRole = (data: any, roleName: string) => {
    return data?.roles?.find((role: any) => role.value?.toLowerCase() === roleName.toLowerCase()) ? true : false
}
