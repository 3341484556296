const SoundControl = ({ handleStart, handleStop, reading }: any) => {
    return (
        <footer className="h-[6vh] shadow-sm border-t flex items-center px-5 py-8 sm:py-6 justify-center fixed bg-white z-10 w-full bottom-0">
            <button disabled={reading} onClick={handleStart} className="text-blue-400 disabled:text-gray-400">
                <i className="zmdi zmdi-play ms-2 text-[40px] "></i>
            </button>
            <button disabled={!reading} onClick={handleStop} className="text-blue-400 disabled:text-gray-400 ms-4">
                <i className="zmdi zmdi-stop ms-2 text-[40px] "></i>
            </button>
        </footer>
    )
}

export default SoundControl
