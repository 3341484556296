import { useSelector } from 'react-redux'
import WhisperComponent from '../WhisperComponent'
import ProfileForm from '../../forms/UserForm/ProfileForm'
import { useState } from 'react'

const Profile = () => {
    const { session } = useSelector((state: any) => state)

    const [modal, setModal] = useState({
        open: false,
        action: 'update',
        data: {},
    })

    const _toggleModal = () => {
        setModal({
            ...modal,
            open: !modal.open,
        })
    }
    return (
        <div>
            <ProfileForm open={modal.open} action="update" handleClose={_toggleModal} />
            <WhisperComponent text="Editar perfil" className="cursor-pointer">
                <div className="flex items-center" onClick={_toggleModal}>
                    <div className="rounded-[50%] bg-blue-300 text-white w-[30px] h-[30px] flex justify-center items-center font-bold">
                        {session?.profile?.name?.charAt(0)?.toUpperCase()}
                    </div>

                    <div className="flex flex-col mx-5">
                        <span className="text-[14px] font-bold">{session?.profile?.name}</span>
                        <span className="text-[11px]">
                            {session?.profile?.roles ? session?.profile?.roles[0]?.label : 'Usuario'}
                        </span>
                    </div>
                </div>
            </WhisperComponent>
        </div>
    )
}

export default Profile
