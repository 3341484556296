import { useNavigate } from 'react-router-dom'

interface IBackButton {
    onClick?: (event: any) => void
}
const BackButton = ({ onClick }: IBackButton) => {
    const navigate = useNavigate()

    const _handleOnClick = (event: any) => {
        if (onClick) onClick(event)
        if (!onClick) navigate(-1)
    }
    return (
        <button onClick={_handleOnClick}>
            <div className="flex items-center">
                <i className="zmdi zmdi-long-arrow-left me-2 "></i>
                <span className='text-[14px]'>Volver</span>
            </div>
        </button>
    )
}

export default BackButton
