import AppRouter from "./router/AppRouter";
import store from './redux/store'
import { Provider } from 'react-redux'

import "./styles/index.css";
import "./styles/_global.scss";

export default function App() {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  )
}