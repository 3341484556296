/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const CreateRoutineSchema = yup.object().shape({
    name: yup
    .string()
    .matches(
      /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
      "Este campo contiene caracteres inválidos"
    )
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required("Este campo es requerido"),
    patient_id: yup.string().required("Este campo es requerido"),
    // command_id: yup.string().required("Este campo es requerido"),
    days_of_week: yup.array().min(1, "Debe seleccionar al menos un día de la semana"),
    start_time: yup.string().required("Este campo es requerido"),
    description: yup.string().required("Este campo es requerido"),
});

export type CreateRoutineType = yup.InferType<typeof CreateRoutineSchema>;

export const CreatePreLoadedRoutineSchema = yup.object().shape({
  name: yup
  .string()
  .matches(
    /^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim,
    "Este campo contiene caracteres inválidos"
  )
  .max(255, "Este campo acepta un máximo de 255 caracteres")
  .required("Este campo es requerido"),
  days_of_week: yup.array().min(1, "Debe seleccionar al menos un día de la semana"),
  start_time: yup.string().required("Este campo es requerido"),
  description: yup.string().required("Este campo es requerido"),
});

export type CreatePreLoadedRoutineType = yup.InferType<typeof CreatePreLoadedRoutineSchema>;

export const CreatePreLoadedRoutineFormSchema = yup.object().shape({
  routines: yup.array().min(1, "Debe seleccionar al menos una rutina"),
  patient_id: yup.string().required("Este campo es requerido"),
});

export type CreatePreLoadedRoutineFormType = yup.InferType<typeof CreatePreLoadedRoutineFormSchema>;