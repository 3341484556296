import { configureStore, Tuple } from "@reduxjs/toolkit";
import sessionSlice from "./slicers/session";
import loaderSlice from "./slicers/loader";

let intialState: {
  session?: any;
} = {};
const storage: any = localStorage.getItem("global_state");
intialState = storage ? JSON.parse(storage) : {};

const saver = (store: any) => (next: any) => (action: any) => {
  const actionType = action.type.split("/");

  let stateToSave = store.getState();
 
  stateToSave = {
    ...stateToSave,
    [actionType[0]]: {
      ...stateToSave[actionType[0]],
      ...action.payload,
    },
  };

  localStorage.setItem("global_state", JSON.stringify({ ...stateToSave }));
  next(action);
};

export default configureStore({
  preloadedState: intialState,
  reducer: {
    session: sessionSlice,
    loader: loaderSlice,
  },
  middleware: () => new Tuple(saver),
  
});
