import { Tooltip, Whisper } from 'rsuite';

const WhisperComponent = (props: any) => {
  const { text, children, capitalizeOff, align, className = "", placement="auto" } = props;
  return (
    <Whisper trigger="hover" placement={placement} controlId={`control-id-auto`} speaker={<Tooltip>{text || ''}</Tooltip>}>
      <div  className={className} style={{ textTransform: capitalizeOff ? 'none' : 'capitalize', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: align || 'center' }}>{children}</div>
    </Whisper>
  );
};

export default WhisperComponent;
